import { useRecoilState, useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import { activeCompanyState } from '../atoms/new.company.atom';
import GuestProjectList from '../components/GuestProjectList';
import { useEffect } from 'react';
import { projectListState } from '../atoms/project.atom';
import { fetchProjectsByClient, fetchProjectsByCompany } from '../utils/api';

const Main = () => {
  const [activeCompany, setActiveCompany] = useRecoilState(activeCompanyState);

  return (
    <>
      <div className=" bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl text-bold">
          {activeCompany.name} Dashboard -{' '}
          <span className=" text-gray-500">
            {format(new Date(), 'MMM d, yyyy')}
          </span>{' '}
        </h2>
        <div className="grid grid-cols-3">
          <div className="col-span-1 px-1 ">
            <h2 className="text-xl font-bold">Monthly Goal Status</h2>
            <div className="border-gray-400 h-56 border rounded p-4">
              <p>
                Delivered Work: <span className="font-bold">!</span> of 4
              </p>
              <p>
                Signed Jobs: <span className="font-bold">!</span> of 3
              </p>
              <p>
                Foundations: <span className="font-bold">!</span> of 8
              </p>
            </div>
            <div className="my-1 grid grid-cols-3">
              <button
                type="submit"
                className="bg-blue-500 text-white px-2 py-1 rounded"
                onClick={() => {
                  return;
                }}
              >
                Call
              </button>
              <button
                type="submit"
                className="bg-gray-300 text-white col-start-3 px-2 py-1 rounded"
                onClick={() => {
                  return;
                }}
              >
                Message
              </button>
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Target Deliveries</h2>
            <div className="border-gray-400 h-56 border rounded p-4">
              <p>List of Projects: </p>
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Imoortant Dates This Month</h2>
            <div className="border-gray-400 h-56 border rounded p-4">
              <p>Independence Day: July 4th, 22222</p>
              <p>Scheduled Start: July 45th, 22222</p>
              <p>Scheduled Delivery: July 45th, 22222</p>
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Company Library</h2>
            <div className="border-gray-400 h-56 border rounded p-4">
              <p>Onboarding Guide</p>
              <p>CAD conventions</p>
              <p>Quickbooks Time - How To</p>
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Important Files</h2>
            <div className="border-gray-400 h-56 border rounded p-4">
              <p>COMING SOON</p>
            </div>
          </div>
          <div className="col-span-1  px-1 ">
            <h2 className="text-xl font-bold">Recent Activity</h2>
          </div>
        </div>
      </div>
      <div className=" bg-white mt-6 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl text-bold">
          Need help with a task or technical question, ask Abbi: Coming soon.
        </h2>
      </div>
    </>
  );
};
export default Main;
