// Define the props type
interface StringListProps {
  items: string[];
  style: object;
}

const TextList: React.FC<StringListProps> = ({ items, style }) => {
  return (
    <ul style={style}>
      {items.map((i) => {
        return <li>{i}</li>;
      })}
    </ul>
  );
};

export default TextList;
