// src/../components/WorkflowStatus.tsx

import React, { useRef, useEffect } from "react";

interface WorkflowStatusProps {
  statuses: string[];
  currentStatus: string | undefined;
}

const WorkflowStatus: React.FC<WorkflowStatusProps> = ({
  statuses,
  currentStatus,
}) => {
  const statusRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (statusRef.current) {
      const currentStatusElement =
        statusRef.current.querySelector(".current-status");
      if (currentStatusElement) {
        const containerWidth = statusRef.current.clientWidth;
        const elementWidth = currentStatusElement.clientWidth;
        const elementLeft = (currentStatusElement as HTMLElement).offsetLeft;
        statusRef.current.scrollLeft =
          elementLeft - containerWidth / 2 + elementWidth / 2;
      }
    }
  }, [currentStatus]);

  return (
    <div className="my-4 w-full overflow-hidden">
      <ul
        className="flex space-x-2 overflow-x-auto no-scrollbar"
        ref={statusRef}
      >
        {statuses.map((status, index) => (
          <li
            key={index}
            className={`flex-shrink-0 w-1/5 text-center p-2 border rounded ${
              status === currentStatus
                ? "bg-green-500 text-white font-bold current-status"
                : "border-gray-300"
            } truncate`}
            title={status}
          >
            {status}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WorkflowStatus;
