import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { activeProjectState } from '../atoms/project.atom';
import MessageModal from './MessageModal';
import { format } from 'date-fns';
import { truncateMessage } from '../utils/misc';
import { Thread } from '../models/general';

const MessagesList: React.FC = () => {
  const project = useRecoilValue(activeProjectState);
  const [selectedThread, setSelectedThread] = useState<Thread>();

  const handleOpenModal = (thread: Thread) => {
    setSelectedThread(thread as Thread);
  };

  const handleCloseModal = () => {
    setSelectedThread(undefined);
  };

  const safeGetLastObject = (messages) => {
    return messages ? messages[messages.length - 1] : undefined;
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg shadow-md">
      {project?.threads?.map((thread) => (
        <div
          key={thread._id}
          className="p-2 mb-2 "
          onClick={() => handleOpenModal(thread)}
        >
          <h3 className="text-xl font-bold text-gray-700">{thread.title}</h3>
          <div className="p-2 text-gray-600 bg-white rounded-lg shadow hover:bg-gray-200 cursor-pointer">
            {safeGetLastObject(thread.messages) ? (
              <>
                <span className="font-bold text-sm text-gray-700">
                  {safeGetLastObject(thread.messages).author.firstName}{' '}
                  {safeGetLastObject(thread.messages).author.lastName}
                </span>
                <span className="text-xs text-gray-500">
                  {' '}
                  {format(
                    new Date(safeGetLastObject(thread.messages).createdAt),
                    'MMM d, yyyy, h:mm a',
                  )}
                </span>
                <p>
                  {truncateMessage(
                    safeGetLastObject(thread.messages).content,
                    40,
                  )}
                </p>
              </>
            ) : (
              <p>Send the First Message</p>
            )}
          </div>
        </div>
      ))}
      {selectedThread && (
        <MessageModal
          projectId={project._id}
          thread={selectedThread}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default MessagesList;
