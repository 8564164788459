import "./App.css";
import "@fontsource/inter";

import { Form } from "./components/forms/Form";
import { RecoilRoot, useRecoilValue } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";

export default function App() {
  return (
    <RecoilRoot>
      <Router>
        <AppRoutes />
      </Router>
    </RecoilRoot>
  );
}
