// src/../pages/ProfilePage.tsx

import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeUserState } from "../atoms/user.atom";
import { updateUser } from "../utils/api";

const ProfilePage = () => {
  const activeUser = useRecoilValue(activeUserState);
  const setActiveUser = useSetRecoilState(activeUserState);

  const [formData, setFormData] = useState({
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    imageUrl: "",
  });

  useEffect(() => {
    if (activeUser) {
      setFormData({
        phone: activeUser.phone || "",
        email: activeUser.email || "",
        firstName: activeUser.firstName || "",
        lastName: activeUser.lastName || "",
        imageUrl: activeUser.imageUrl || "",
      });
    }
  }, [activeUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({ ...formData, imageUrl: e.target.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = { ...activeUser, ...formData };
      await updateUser(activeUser._id, updatedUser);
      setActiveUser(updatedUser);
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  if (!activeUser) {
    return <div>Loading...</div>;
  }

  return (
    <div className=" bg-white p-6 rounded-lg shadow-lg">
      <h1 className="my-4 text-xl font-bold text-center">Profile Page</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Phone:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Profile Image:</label>
          <input type="file" onChange={handleImageChange} />
          {formData.imageUrl && <img src={formData.imageUrl} alt="Profile" />}
        </div>
        <button
          className="my-4 rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto"
          type="submit"
        >
          Update Profile
        </button>
      </form>
    </div>
  );
};

export default ProfilePage;
