// src/utils/tokenManager.ts

import { jwtDecode } from 'jwt-decode';

const TOKEN_KEY = 'jwt_token';
const LINK_TOKEN_KEY = 'link_token';

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token || isTokenExpired(token)) {
    clearToken();
    return null;
  }

  return token;
};

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  // Optionally, clear additional state or notify other parts of the app
};

export const storeLinkToken = (token: string) => {
  localStorage.setItem(LINK_TOKEN_KEY, token);
};

export const getLinkToken = () => {
  const token = localStorage.getItem(LINK_TOKEN_KEY);

  if (!token || isTokenExpired(token)) {
    clearLinkToken();
    return null;
  }

  return token;
};

export const clearLinkToken = () => {
  localStorage.removeItem(LINK_TOKEN_KEY);
  // Optionally, clear additional state or notify other parts of the app
};

const isTokenExpired = (token: string): boolean => {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    if (decoded.exp * 1000 < Date.now()) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('Failed to decode token', error);
    return true; // Assume token is expired or invalid if decoding fails
  }
};
