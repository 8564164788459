import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { format } from 'date-fns';
import { activeCompanyState } from '../atoms/new.company.atom';
import { useEffect, useState } from 'react';
import { projectListState } from '../atoms/project.atom';
import {
  fetchProjectsByClient,
  fetchProjectsByCompany,
  updateProject,
} from '../utils/api';
import { activeUserState } from '../atoms/user.atom';
import { clearToken } from '../utils/tokenManager';
import { useNavigate } from 'react-router-dom';
import FileUploadModal from '../components/FileUploadComponent';
import FileList from '../components/FileList';
import MilestoneList from '../components/MilestoneList';
import { Project } from '../models/general';
import { Tooltip } from 'antd';

const GuestPage = () => {
  const setActiveUser = useSetRecoilState(activeUserState);
  const setActiveCompany = useSetRecoilState(activeCompanyState);
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const activeCompany = useRecoilValue(activeCompanyState);
  const [projects, setProjects] = useRecoilState(projectListState);

  useEffect(() => {
    const fetchCompanyData = async (companyId: string) => {
      console.log('getting company data');
      try {
        const response = await fetchProjectsByCompany(companyId);
        setProjects(response.data.reverse());
      } catch (error) {
        console.error('Failed to fetch projects', error);
      }
    };
    const fetchClientData = async (companyId: string) => {
      console.log('getting client data');
      try {
        const response = await fetchProjectsByClient(companyId);
        setProjects(response.data.reverse());
      } catch (error) {
        console.error('Failed to fetch projects', error);
      }
    };
    if (
      activeCompany &&
      activeCompany.subscriptionLevel &&
      activeCompany.subscriptionLevel !== 'Guest'
    ) {
      fetchCompanyData(activeCompany._id);
    } else if (activeCompany) {
      fetchClientData(activeCompany._id);
    }
  }, [activeCompany]);

  const signOut = async () => {
    try {
      clearToken();
      setActiveUser(null);
      setActiveCompany(null);
      navigate('/');
    } catch (error) {
      console.error('Signin failed', error);
    }
  };

  const handleUploadFileClick = (project: Project) => {
    setSelectedProject(project);
    setIsFileUploadModalOpen(true);
  };

  const handleSendMessageClick = (project: Project) => {
    setSelectedProject(project);
    setIsMessageModalOpen(true);
  };
  const handleFileUploadSuccess = async (fileInfo: any) => {
    let updatedProject = {
      ...selectedProject,
      clientDocuments: [...selectedProject?.clientDocuments, fileInfo],
    };

    try {
      const response = await updateProject(selectedProject._id, updatedProject);
      setSelectedProject(response.data);
      setProjects((projects: any[]) =>
        projects.map((proj) =>
          proj._id === selectedProject._id ? response.data : proj,
        ),
      );
    } catch (error) {
      console.error('Failed to update project', error);
    }
  };

  return (
    <div className=" bg-gray-200 ">
      <div className="bg-white p-2 flex justify-between">
        <h1 className="text-2xl font-bold ">
          {activeCompany.name} - Guest Dashboard{' '}
        </h1>
        <button
          onClick={() => signOut()}
          className=" px-4 text-sm bg-black text-white font-semibold  hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
        >
          Log Out
        </button>
      </div>

      <div className="p-6 ">
        {projects.map((project: Project) => (
          <div
            key={project._id}
            className="p-4 mb-4 bg-white rounded-lg shadow-md"
          >
            <div className="flex justify-between mb-2">
              <h2 className="text-xl font-semibold capitalize">
                {project.address}
              </h2>
              {project.workFlowStatus === 'letter agreement - sent' && (
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  onClick={() => {
                    navigate(`/sign-agreement/${project._id}`);
                  }}
                >
                  Signature Needed - Letter Agreement
                </button>
              )}
              <h2 className="text-xl text-slate-500 font-semibold capitalize">
                Status: {project.workFlowStatus}
              </h2>
            </div>

            <div className="grid grid-cols-3">
              <div className="mx-1">
                <h2 className="text-l  font-semibold capitalize">Milestones</h2>
                <div className="border-gray-400 h-56 border rounded p-2 overflow-auto">
                  <MilestoneList
                    project={project}
                    isViewOnly={true}
                    updateProject={handleFileUploadSuccess}
                  />
                </div>
              </div>

              <div className="mx-1">
                <h2 className="text-l  font-semibold capitalize">
                  Files{' '}
                  <Tooltip title="Upload a File">
                    <button
                      className="px-1 mb-1 bg-green-500 text-white rounded  text-xs hover:bg-green-600"
                      onClick={() => handleUploadFileClick(project)}
                    >
                      + Upload
                    </button>
                  </Tooltip>
                </h2>
                <div className="border-gray-400 h-56 border rounded p-2 overflow-auto">
                  <FileList
                    heading={'Uploads'}
                    files={project.clientDocuments}
                  />
                  <br />
                </div>
              </div>

              <div className="mx-1">
                <h2 className="text-l  font-semibold capitalize">
                  Deliverables
                </h2>
                <div className="border-gray-400 h-56 border rounded p-2 overflow-auto">
                  {project.signedContracts &&
                    project.signedContracts?.length > 0 && (
                      <div>
                        <button
                          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                          onClick={() => {
                            navigate(`/sign-agreement/${project._id}`);
                          }}
                        >
                          View Letter Agreement
                        </button>
                        <br />
                      </div>
                    )}

                  <FileList
                    heading={'Plan Sets'}
                    files={project.deliverableDocuments}
                  />
                </div>
              </div>
              {/* <button
              className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
              onClick={() => handleSendMessageClick(project)}
            >
              Send a Message - Coming Soon
            </button> */}
            </div>
          </div>
        ))}

        {selectedProject && (
          <>
            <FileUploadModal
              isGuest={true}
              isOpen={isFileUploadModalOpen}
              onClose={() => setIsFileUploadModalOpen(false)}
              projectId={selectedProject._id}
              companyId={selectedProject.company._id || selectedProject.company}
              onFileUploadSuccess={(fileInfo) => {
                handleFileUploadSuccess(fileInfo);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default GuestPage;
