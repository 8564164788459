import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Modal, Radio, Select, Tooltip } from 'antd'; // Assuming you are using antd for date picker
import { Milestone, Project } from '../models/general';
import { formatDate } from 'date-fns';
import { BorderOutlined, CheckOutlined } from '@ant-design/icons';

const { Option } = Select;

const MilestoneList: React.FC<{
  project: Project;
  isViewOnly: boolean;
  updateProject?: (project: Project) => void;
}> = ({ project, isViewOnly, updateProject }) => {
  const [localMilestones, setLocalMilestones] = useState<Milestone[]>(
    project.milestones || [],
  );
  useEffect(() => {
    setLocalMilestones(project.milestones || []);
  }, [project.workFlowStatus]);

  const [filter, setFilter] = useState<'all' | 'completed' | 'incomplete'>(
    'all',
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMilestoneTitle, setNewMilestoneTitle] = useState('');
  const [selectedTitle, setSelectedTitle] = useState(''); // For selected predefined title

  const predefinedTitles = [
    'Work Started',
    'Schematic Delivery',
    '100% Plans Delivery',
  ];

  const handleMarkAsComplete = (
    title: string,
    completion: Date | undefined,
  ) => {
    if (isViewOnly) return;
    let val = new Date();
    if (completion) {
      val = undefined;
    }
    const updatedMilestones = localMilestones.map((milestone) =>
      milestone.title === title
        ? { ...milestone, completionDate: val }
        : milestone,
    );
    setLocalMilestones(updatedMilestones);
    updateProject({ ...project, milestones: updatedMilestones });
  };

  const handleTargetDateChange = (date: any, title: string) => {
    if (isViewOnly) return;
    const updatedMilestones = localMilestones.map((milestone) =>
      milestone.title === title
        ? { ...milestone, targetDate: date ? date.toDate() : null }
        : milestone,
    );
    setLocalMilestones(updatedMilestones);
    updateProject({ ...project, milestones: updatedMilestones });
  };

  const handleAddMilestone = () => {
    const finalTitle =
      newMilestoneTitle ||
      selectedTitle ||
      `Milestone ${localMilestones.length + 1}`;
    const newMilestone: Milestone = {
      title: finalTitle,
      targetDate: undefined,
      completionDate: undefined,
    };
    const updatedMilestones = [...localMilestones, newMilestone];
    setLocalMilestones(updatedMilestones);
    updateProject({ ...project, milestones: updatedMilestones });
    setIsModalVisible(false); // Close the modal after adding milestone
    setNewMilestoneTitle(''); // Clear the input after submission
    setSelectedTitle('');
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewMilestoneTitle(''); // Clear the input if the modal is cancelled
  };

  const handleFilterChange = (e: any) => {
    setFilter(e.target.value);
  };

  const filteredMilestones = localMilestones.filter((milestone) => {
    if (filter === 'completed') {
      return milestone.completionDate;
    } else if (filter === 'incomplete') {
      return !milestone.completionDate;
    }
    return true; // If "all" is selected, show all milestones
  });

  return (
    <div>
      <Radio.Group
        onChange={handleFilterChange}
        value={filter}
        style={{ marginBottom: 16 }}
      >
        <Radio.Button value="all">All</Radio.Button>
        <Radio.Button value="completed">Completed</Radio.Button>
        <Radio.Button value="incomplete">Future</Radio.Button>
      </Radio.Group>
      {filteredMilestones.map((milestone, index) => (
        <div key={index} className="milestone-item">
          <h3 className="font-bold">
            {milestone.title}{' '}
            {milestone.completionDate ? (
              <Tooltip title={isViewOnly ? '' : 'Mark as Not Completed'}>
                <button
                  className="bg-blue-500 text-white text-sm px-2 py-1 my-1 rounded"
                  onClick={() =>
                    handleMarkAsComplete(
                      milestone.title,
                      milestone.completionDate,
                    )
                  }
                >
                  <CheckOutlined />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title={isViewOnly ? '' : 'Mark as Completed'}>
                <button
                  className="bg-red-500 text-white text-sm px-2 py-1 my-1 rounded"
                  onClick={() =>
                    handleMarkAsComplete(
                      milestone.title,
                      milestone.completionDate,
                    )
                  }
                >
                  <BorderOutlined />
                </button>
              </Tooltip>
            )}{' '}
            {!isViewOnly && (
              <Tooltip title="Select a Target Date">
                <DatePicker
                  style={{ borderStyle: 'none', width: 40 }}
                  inputReadOnly
                  onChange={(date) =>
                    handleTargetDateChange(date, milestone.title)
                  }
                />
              </Tooltip>
            )}
          </h3>

          {milestone.completionDate ? (
            <p className="text-sm">
              Completed On:{' '}
              {formatDate(milestone.completionDate, 'MMM dd, yyyy')}
            </p>
          ) : (
            <p className="text-sm">
              Target Date:{' '}
              {milestone.targetDate
                ? formatDate(milestone.targetDate, 'MMM dd, yyyy')
                : 'Not Set'}
            </p>
          )}
          <br />
        </div>
      ))}
      {!isViewOnly && <button onClick={showModal}>+ New Milestone</button>}
      <Modal
        title="Add New Milestone"
        open={isModalVisible}
        onOk={handleAddMilestone}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: '100%', marginBottom: 16 }}
          placeholder="Select a predefined title"
          onChange={(value) => setSelectedTitle(value)}
          value={selectedTitle}
        >
          {predefinedTitles.map((title) => (
            <Option key={title} value={title}>
              {title}
            </Option>
          ))}
        </Select>
        <Input
          placeholder="Or enter your custom title"
          value={newMilestoneTitle}
          onChange={(e) => setNewMilestoneTitle(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default MilestoneList;
