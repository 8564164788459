import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import letterHead from './../images/letterHead.png';

import { useOutletContext } from 'react-router-dom';
import { Milestone, Project } from '../models/general';
import LetterDisplay from './HTMLPDF';
import Terms from './Exhibits/Terms';
import AdditionalServices from './Exhibits/AdditionalServices';
import { useReactToPrint } from 'react-to-print';
import { toolbarOptions } from './quill/toolbar';
import './quill/customQuillFormats';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activeUserState } from '../atoms/user.atom';
import { formatCurrency } from '../utils/misc';
import { activeCompanyState } from '../atoms/new.company.atom';
import { lOETotals } from '../atoms/project.atom';
import { createThread, sendMessage } from '../utils/api';
import { addDays } from 'date-fns';

interface OutletContext {
  activeProject: Project;
  updateProjectList: (project: Project) => void;
}

type QuillModules = {
  toolbar: Array<any>;
  customClasses: {
    customBlock: string;
    customInline: string;
  };
};

const modules: QuillModules = {
  toolbar: toolbarOptions,
  customClasses: {
    customBlock: 'tailwind-class-for-block',
    customInline: 'tailwind-class-for-inline',
  },
};

// const formats: Array<string> = [
//   'header',
//   'font',
//   'list',
//   'bold',
//   'italic',
//   'underline',
//   'align',
//   'link',
//   'image',
//   'color',
//   'background',
//   'custom-block',
//   'custom-inline',
// ];

let Inline = Quill.import('blots/inline');
class BoldBlot extends Inline {}
BoldBlot.blotName = 'bold';
BoldBlot.tagName = 'strong';
Quill.register('formats/bold', BoldBlot);

const formats = ['bold'];

function EditableAgreement({ initialContent }) {
  const [editorContent, setEditorContent] = useState(initialContent);
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const totals = useRecoilValue(lOETotals);
  const targetRef = useRef();
  const { activeProject, updateProjectList } =
    useOutletContext<OutletContext>();
  console.log(activeProject);
  const saveUpdates = () => {
    const updatedProject = {
      ...activeProject,
      agreementContent: editorContent,
    };
    updateProjectList(updatedProject);
  };
  const [showModal, setShowModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');

  const openModal = () => {
    if (activeUser && activeProject) {
      const defaultMessage = `
        ${activeProject.client.owner.firstName}, I hope this email finds you well. Please find your Proposal for ${activeProject.address} at the link below. Feel free to reach out if you have any questions or would like to discuss it. Assuming things look good, please sign the Letter agreement and we will send out a deposit invoice that can be paid digitally.

As soon as we receive the deposit we will get your project on schedule.

We are looking forward to working with you.

Sincerely,
${activeUser.firstName} ${activeUser.lastName}
      `;
      setMessageContent(defaultMessage);
      setShowModal(true);
    } else {
      console.error('User or project information is missing');
    }
  };

  const closeModal = () => setShowModal(false);

  const handleSendMessage = async () => {
    try {
      // Check if "Letter Agreement" thread exists in activeProject.threads
      let thread = activeProject?.threads?.find(
        (t) => t.title === 'Letter Agreement',
      );

      if (!thread) {
        // Create the thread if it doesn't exist
        const threadResponse = await createThread(
          activeProject._id,
          'Letter Agreement',
        );
        thread = threadResponse.data;
      }

      if (!thread) throw Error('No thread created or found');

      // Send message with notification
      try {
        const res = await sendMessage(
          activeProject._id,
          thread._id,
          messageContent,
          true,
          true,
        );
        setShowModal(false);
        const updatedMilestones =
          activeProject.milestones && activeProject.milestones?.length > 1
            ? activeProject.milestones?.map((milestone: Milestone) => {
                if (milestone.title === 'Letter Agreement Sent') {
                  return { ...milestone, completionDate: new Date() };
                } else if (milestone.title === 'Letter Agreement Signed') {
                  return { ...milestone, targetDate: addDays(new Date(), 7) };
                } else {
                  return milestone;
                }
              })
            : [
                ...(activeProject.milestones || []),
                { title: 'Letter Agreement Sent', completionDate: new Date() },
                {
                  title: 'Letter Agreement Signed',
                  targetDate: addDays(new Date(), 7),
                },
                {
                  title: 'Deposit Sent',
                },
                {
                  title: 'Paid Deposit',
                },
                {
                  title: 'Project Scheduled',
                },
                {
                  title: 'Work Started',
                },
              ];
        const updatedProject = {
          ...res.data,
          workFlowStatus: 'letter agreement - sent',
          agreementContent: editorContent,
          milestones: updatedMilestones,
        };
        updateProjectList(updatedProject);
      } catch {}

      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  const handleSignedAgreement = (action: string) => {
    const updatedProject = {
      ...activeProject,
      workFlowStatus:
        action === 'signed' ? 'letter agreement - signed' : 'closed - lost',
    };
    updateProjectList(updatedProject);
  };
  const handleReOpenEstimate = () => {
    const updatedProject = {
      ...activeProject,
      workFlowStatus: 'letter agreement - sent',
    };
    updateProjectList(updatedProject);
  };
  const handlePrintAndUpdate = () => {
    const updatedProject = {
      ...activeProject,
      workFlowStatus: 'letter agreement - sent',
      agreementContent: editorContent,
    };
    updateProjectList(updatedProject);
    // Print the content
    if (targetRef && targetRef.current) {
      handlePrint();
    }
  };

  // This is the packaged print function this needs to replace the other methods. Will also need to apply some styling fixes coming from the text editor
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  const handleReprint = () => {
    // Print the content
    if (targetRef && targetRef.current) {
      handlePrint();
    }
  };
  const doesNeedLetter =
    activeProject.workFlowStatus === 'estimate - finished' ||
    activeProject.workFlowStatus === 'letter agreement - sent';
  const isEstimating =
    activeProject.workFlowStatus === 'estimating' ||
    activeProject.workFlowStatus === 'initiated';
  const isClosedAgreement =
    activeProject.workFlowStatus !== 'initiated' &&
    activeProject.workFlowStatus !== 'estimating' &&
    activeProject.workFlowStatus !== 'estimate - finished' &&
    activeProject.workFlowStatus !== 'letter agreement - sent';

  useEffect(() => {
    setEditorContent(initialContent);
  }, [initialContent]);

  return activeProject.signedContracts &&
    activeProject.workFlowStatus == 'letter agreement - signed' ? (
    <div className="container mx-auto my-4 p-4 rounded">
      <LetterDisplay content={activeProject.signedContracts[0]} />
    </div>
  ) : (
    <>
      {doesNeedLetter && (
        <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
          <div className="grid grid-cols-4 content-center mt-2">
            <button
              className="rounded bg-gray-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2"
              onClick={() => saveUpdates()}
            >
              Save Progress
            </button>

            <button
              className="rounded bg-blue-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2"
              onClick={openModal}
            >
              Send Letter Agreement
            </button>
            <button
              className="rounded bg-green-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2"
              onClick={() => handleSignedAgreement('signed')}
            >
              Mark as Signed
            </button>
            <button
              className="rounded bg-red-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2"
              onClick={() => handleSignedAgreement('lost')}
            >
              Mark as Closed
            </button>
          </div>
          <h1>
            <strong>Letter Agreement Editor</strong> - Be Sure to Save Your
            Progress
          </h1>
          <ReactQuill value={editorContent} onChange={setEditorContent} />
          <button
            className="rounded bg-gray-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2"
            onClick={() => handleReprint()}
          >
            Print a Copy
          </button>
        </div>
      )}
      <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
        {isEstimating && (
          <h1 className="text-center text-red-700">
            Estimate Still In Process - Confirm Estimate to Edit and Print
            Letter Agrement
          </h1>
        )}
        {isClosedAgreement && (
          <>
            <button
              className="rounded bg-gray-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2"
              onClick={() => handleReOpenEstimate()}
            >
              ReOpen Contract
            </button>
            <button
              className="rounded bg-blue-500 px-4 py-2 text-white w-50 font-medium mx-auto my-2 mx-2"
              onClick={() => handleReprint()}
            >
              Reprint Print PDF
            </button>
          </>
        )}
      </div>

      <div ref={targetRef} className="quill">
        <div className=" bg-white p-6 rounded-lg shadow-lg mt-2">
          <img
            className="w-100 inline"
            src={letterHead}
            alt="Abacus Engineering Inc."
          ></img>
          <LetterDisplay content={editorContent} />
          <h2 className="font-bold text-2xl">Fee Schedule</h2>
          <br />
          <div className="mx-6">
            {activeProject.disciplines.map((discipline: string) => (
              <>
                <div className="grid grid-cols-2">
                  <p>{discipline.toUpperCase()} Design & Drawings</p>
                  <p>{formatCurrency(totals[discipline])}</p>
                </div>
              </>
            ))}
            <div className="grid grid-cols-2">
              <p className="font-bold">Total</p>
              <p className="font-bold">{formatCurrency(totals?.total)}</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="grid grid-cols-2">
            <div className="col-span-1">
              <h3>Client: {activeProject.client?.name}</h3>
              <p>
                Representative: {activeProject.client?.owner?.firstName}{' '}
                {activeProject.client?.owner?.lastName}
              </p>
              <br />
              <p>Signature: _________________________</p>
              <p>Date : ______________________</p>
            </div>
            <div className="col-span-1">
              <h3>Engineer: {activeProject.company?.name}</h3>
              <p>
                Representative: {activeUser?.firstName} {activeUser?.lastName}
              </p>
              <br />
              <p>Signature: _________________________</p>
              <p>Date : ______________________</p>
            </div>
          </div>
          <br />
          <br />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
          <Terms />
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
          <AdditionalServices />
        </div>
        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg w-1/2">
              <h2 className="text-2xl font-semibold mb-4">
                Send Letter Agreement
              </h2>
              <h2 className="text-xl font-semibold mb-4">
                To: {activeProject.client.email}
              </h2>
              <p>Message:</p>
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                rows={10}
                className="w-full p-2 border rounded mb-4"
                placeholder="Edit the message content here..."
              />
              <div className="flex justify-end">
                <button
                  onClick={closeModal}
                  className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSendMessage}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default EditableAgreement;
