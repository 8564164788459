import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { activeUserState } from '../atoms/user.atom';
import { activeCompanyState } from '../atoms/new.company.atom';
import { setToken, getToken, clearToken } from '../utils/tokenManager';

const useAuth = () => {
  const setActiveUser = useSetRecoilState(activeUserState);
  const setActiveCompany = useSetRecoilState(activeCompanyState);
  const history = useNavigate();

  const clearAuthData = () => {
    setActiveUser(null);
    setActiveCompany(null);
    history('/login');
  };

  return { setToken, getToken, clearToken, clearAuthData };
};

export default useAuth;
