import { LOEItemI } from '../models/general';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const classifications = ['commercial', 'government', 'residential'];

export const truncateMessage = (message: string, maxLenght: number) => {
  return message.length > maxLenght
    ? message.substring(0, maxLenght) + '...'
    : message;
};

export const formatCurrency = (number?: number | bigint) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number || 0);
};

// Not Used
export const workFlowStatuses = [
  'initiated',
  'holding',
  'estimating',
  'estimate - finished',
  'letter agreement - sent',
  'letter agreement - signed',
  'Deposit - Sent',
  'Deposit - Paid',
  'scheduling - needs schedule',
  'scheduleing - waiting to start',
  'schematics - in progress',
  'schematics - blocked',
  'schematics - finished',
  'design plans - in progress',
  'design plans - blocked',
  'design plans - finished',
  'AHJ - submitted',
  'AHJ - has comments',
  'AHJ - addressing comments',
  'AHJ - approved',
  'closed',
  'closed - lost',
];

export const schematicSheets: {} = {
  cover: ['Cover'],
  civil: ['Civil Site'],
  foundation: [],
  architecture: [
    'Architectural Floor Plan',
    'Architectural Exterior Elevations',
  ],
  electrical: ['Electrical Lighting Layout'],
  mechanical: ['Mechanical Duct Layout'],
  plumbing: [],
  structural: [],
};

export const constructionSheets: {} = {
  cover: ['Cover'],
  civil: [
    'Civil General Notes',
    'Civil Survey',
    'Civil Site Layout',
    'Civil Utility Plans',
    'Civil Grading Plan',
    'Civil Erosion Control',
    'Civil Detail',
  ],
  foundation: ['Foundation / Pier Plan', 'Foundation Notes and Details'],
  architecture: [
    'Architectural Floor Plan',
    'Architectural Dimension',
    'Architectural Schedules ',
    'Architectural Elevations',
    'Architectural Sections and Details',
    'Handicap Notes and Details',
  ],
  electrical: [
    'Electrical Power Plan Sheet',
    'Electrical Lighting Plan Sheet',
    'Electrical Panel and Riser Diagram Plan Sheet',
    'Electrical Notes and Details',
  ],
  mechanical: ['Mechanical Plan', 'Mechanical Notes and Details'],
  plumbing: [
    'Plumbing Sanitary Plan',
    'Plumbing Water Plan',
    'Plumbing Gas Plan',
    'Plumbing Sanitary, Water, and Gas Riser Diagram',
    'Plumbing Schedule Notes and Details',
  ],
  structural: [
    'Structural Plan',
    'Structural Roof Framing and Wall Framing',
    'Structural Sections',
    'Structural Notes and Details',
  ],
};

export const disciplines: string[] = [
  'civil',
  'foundation',
  'architecture',
  'electrical',
  'mechanical',
  'plumbing',
  'structural',
];

// Create a map for quick lookup of the desired order
export const disciplineMap: { [key: string]: number } = {};
disciplines.forEach((key, index) => {
  disciplineMap[key] = index;
});

// This should be built from manages templates once the back end is built
export const templates: string[] = [
  'foundation',
  'small warehouse',
  'office remodel',
  'storage facility',
  'restaurant remodel',
  'large scope',
  'matts scope',
];

export const agreementTemaplate = {
  generalScopes: [
    'Client has fully and accurately communicated the intended use of requested changes or improvements.',
    'Design of any off site utilities that may be required are out of the scope of this proposal',
    'The contractor shall be responsible for all SWPPP related inspections and reporting.',
    'Pricing quoted here expire if deposit funds are received not received within 60 days of issuance.',
  ],
  clientResponsibilities: [
    'Deliver existing site plans and any existing drawings to Engineer 2 weeks prior to start of work. Digital delivery should be sent via email to “engineers@abacusengineers.com”',
    'Provide primary contact information for design questions prior to start of work.',
    'Provide primary billing address and contact information with delivery of Deposit Payment',
  ],
};

export const calculateItemTotal = (item: LOEItemI) => {
  return (
    item.hoursPE * hourlyRates.pe +
    item.hoursEIT * hourlyRates.eit +
    item.hoursDraftsman * hourlyRates.draftsman +
    item.hoursAdmin * hourlyRates.admin +
    item.dollarsSub +
    item.dollarsMilage
  );
};

export const hourlyRates = {
  pe: 160,
  eit: 120,
  draftsman: 80,
  admin: 80,
};

export const LOETable = {
  admin: {
    lineItems: [
      {
        title: 'Construction Coordination',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  architecture: {
    lineItems: [
      {
        title: 'Research Existing Conditions',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  civil: {
    lineItems: [
      {
        title: 'Make 1 Site Visit',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  electrical: {
    lineItems: [
      {
        title: 'Research Existing Electrical',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  foundation: {
    lineItems: [
      {
        title: 'Research 811 Call and Review',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  mechanical: {
    lineItems: [
      {
        title: 'Calculation Heat Load',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  plumbing: {
    lineItems: [
      {
        title: 'Calculate Plumbing',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  structural: {
    lineItems: [
      {
        title: 'Design Structural Main Frame',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
};

export const LargeLOETable = {
  admin: {
    lineItems: [
      {
        title: 'Construction Coordination',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  architecture: {
    lineItems: [
      {
        title: 'Research Existing Conditions',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Floor Plan Layout (Assume 1 change -  8 hours)',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Arch Elevations',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Arch Sections',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Roof Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Floor Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Dimension Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Schedules',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Elevations',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Roof Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Sections and Details',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Handicap Notes and Details',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'That 1 Detail that is always a pain to make',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review and Correct Red Lines',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 1',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  civil: {
    lineItems: [
      {
        title: 'Make 1 Site Visit',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Gas Budget',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Attend Kick-Off Meeting (Assume - 1)',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Perform Topographic Survey',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Domestic and Fire Service Water to Building',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Sanitary Sewer Line and Profile',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Detention',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Cover',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet General Notes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Survey',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Demolition',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Site and Dimension Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Water & Sanitary',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Grading Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Erosion',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet DAM ',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'That 1 Detail that is always a pain to make',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review and Correct Red Lines for 95% Plans',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Owner Review Comments and Make Changes for 100% Plans',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 1',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 1 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 1 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  electrical: {
    lineItems: [
      {
        title: 'Research Existing Electrical',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Calculations (Fault Current, Comm Check, Etc)',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Power Layout',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Lighting Layout',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Power Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Lighting Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Panel and Riser Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Electrical Notes and Details',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'That 1 Detail that is always a pain to make',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review and Correct Red Lines',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 1',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 1 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 2 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  foundation: {
    lineItems: [
      {
        title: 'Research 811 Call and Review',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Field Drilling (Mid-Tex)',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Field Testing (Mid-Tex)',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Existing Arch. As-Builts',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Foundation Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Notes and Details',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review and Redlines',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Inspection Form and Rebar',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Inspection Acceptance Letter',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  mechanical: {
    lineItems: [
      {
        title: 'Calculation Heat Load',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Mechanical Layout',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Equipment Selection',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Mechanical Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Mecahnical Notes and Details',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'That 1 Detail that is always a pain to make',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review and Correct Red Lines',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 1',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 1 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 2 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  plumbing: {
    lineItems: [
      {
        title: 'Calculate Plumbing',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Sanitary / Water Layout',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Gas Layout',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Sanitary',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Water',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Gas',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Sanitary / Water / Gas Diagram',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Plumbing Schedule and Notes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'That 1 Detail that is always a pain to make',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review and Correct Red Lines',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 1',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments-1 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments-1 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
  structural: {
    lineItems: [
      {
        title: 'Design Structural Main Frame',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Structural Roof ',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Design Structural Wall',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Structural Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Roof Framing Plan',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Section Elevations',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Sheet Structural Notes and Details',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review and Correct Red Lines',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 1',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 1 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title:
          'Coordinate Collating of Plans, Printing, and Submit to City - 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Review City Comments 2 and Make Changes',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
      {
        title: 'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
        hoursPE: 0,
        hoursEIT: 0,
        hoursDraftsman: 0,
        hoursAdmin: 0,
        dollarsSub: 0,
        dollarsMilage: 0,
      },
    ],
  },
};

//these should be replaced with a template list retrieved from the business account.
export const LOEtemplates = {
  foundation: {
    foundation: {
      lineItems: [
        {
          title: 'Standard Foundation',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 1,
          dollarsSub: 2000,
          dollarsMilage: 0,
        },
      ],
    },
  },
  'small warehouse': {
    admin: {
      lineItems: [
        {
          title: 'Construction Coordination',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    architecture: {
      lineItems: [
        {
          title: 'Research Existing Conditions',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Floor Plan Layout (Assume 1 change -  8 hours)',
          hoursPE: 4,
          hoursEIT: 4,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Elevations',
          hoursPE: 4,
          hoursEIT: 8,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Sections',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Floor Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Schedules',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sections and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Handicap Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 0,
          hoursEIT: 1,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 2,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    civil: {
      lineItems: [
        {
          title: 'Make 1 Site Visit',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Gas Budget',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 20,
        },
        {
          title: 'Attend Kick-Off Meeting (Assume - 1)',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Perform Topographic Survey',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Domestic and Fire Service Water to Building',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary Sewer Line and Profile',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Detention',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Cover',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet General Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Survey',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Demolition',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Site and Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water & Sanitary',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Grading Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Erosion',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet DAM ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines for 95% Plans',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Owner Review Comments and Make Changes for 100% Plans',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    electrical: {
      lineItems: [
        {
          title: 'Research Existing Electrical',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Calculations (Fault Current, Comm Check, Etc)',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Power Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Lighting Layout',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Power Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Lighting Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Panel and Riser Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Electrical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    foundation: {
      lineItems: [
        {
          title: 'Research 811 Call and Review',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 1,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Drilling (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 1150,
          dollarsMilage: 0,
        },
        {
          title: 'Field Testing (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 100,
          dollarsMilage: 0,
        },
        {
          title: 'Design Existing Arch. As-Builts',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Foundation Plan',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Redlines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Form and Rebar',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Acceptance Letter',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    mechanical: {
      lineItems: [
        {
          title: 'Calculation Heat Load',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Mechanical Layout',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Equipment Selection',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mechanical Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mecahnical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    plumbing: {
      lineItems: [
        {
          title: 'Calculate Plumbing',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary / Water Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Gas Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Gas',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary / Water / Gas Diagram',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Plumbing Schedule and Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments-1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    structural: {
      lineItems: [
        {
          title: 'Design Structural Main Frame',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Roof ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Wall',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Framing Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Section Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
  },
  'office remodel': {
    admin: {
      lineItems: [
        {
          title: 'Construction Coordination',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    architecture: {
      lineItems: [
        {
          title: 'Research Existing Conditions',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Floor Plan Layout (Assume 1 change -  8 hours)',
          hoursPE: 4,
          hoursEIT: 4,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Elevations',
          hoursPE: 4,
          hoursEIT: 8,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Sections',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Floor Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Schedules',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sections and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Handicap Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 0,
          hoursEIT: 1,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 2,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    civil: {
      lineItems: [
        {
          title: 'Make 1 Site Visit',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Gas Budget',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 20,
        },
        {
          title: 'Attend Kick-Off Meeting (Assume - 1)',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Perform Topographic Survey',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Domestic and Fire Service Water to Building',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary Sewer Line and Profile',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Detention',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Cover',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet General Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Survey',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Demolition',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Site and Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water & Sanitary',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Grading Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Erosion',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet DAM ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines for 95% Plans',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Owner Review Comments and Make Changes for 100% Plans',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    electrical: {
      lineItems: [
        {
          title: 'Research Existing Electrical',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Calculations (Fault Current, Comm Check, Etc)',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Power Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Lighting Layout',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Power Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Lighting Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Panel and Riser Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Electrical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    foundation: {
      lineItems: [
        {
          title: 'Research 811 Call and Review',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 1,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Drilling (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 1150,
          dollarsMilage: 0,
        },
        {
          title: 'Field Testing (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 100,
          dollarsMilage: 0,
        },
        {
          title: 'Design Existing Arch. As-Builts',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Foundation Plan',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Redlines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Form and Rebar',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Acceptance Letter',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    mechanical: {
      lineItems: [
        {
          title: 'Calculation Heat Load',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Mechanical Layout',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Equipment Selection',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mechanical Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mecahnical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    plumbing: {
      lineItems: [
        {
          title: 'Calculate Plumbing',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary / Water Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Gas Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Gas',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary / Water / Gas Diagram',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Plumbing Schedule and Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments-1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    structural: {
      lineItems: [
        {
          title: 'Design Structural Main Frame',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Roof ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Wall',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Framing Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Section Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
  },
  'storage facility': {
    admin: {
      lineItems: [
        {
          title: 'Construction Coordination',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    architecture: {
      lineItems: [
        {
          title: 'Research Existing Conditions',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Floor Plan Layout (Assume 1 change -  8 hours)',
          hoursPE: 4,
          hoursEIT: 4,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Elevations',
          hoursPE: 4,
          hoursEIT: 8,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Sections',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Floor Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Schedules',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sections and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Handicap Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 0,
          hoursEIT: 1,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 2,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    civil: {
      lineItems: [
        {
          title: 'Make 1 Site Visit',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Gas Budget',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 20,
        },
        {
          title: 'Attend Kick-Off Meeting (Assume - 1)',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Perform Topographic Survey',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Domestic and Fire Service Water to Building',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary Sewer Line and Profile',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Detention',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Cover',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet General Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Survey',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Demolition',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Site and Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water & Sanitary',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Grading Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Erosion',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet DAM ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines for 95% Plans',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Owner Review Comments and Make Changes for 100% Plans',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    electrical: {
      lineItems: [
        {
          title: 'Research Existing Electrical',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Calculations (Fault Current, Comm Check, Etc)',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Power Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Lighting Layout',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Power Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Lighting Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Panel and Riser Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Electrical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    foundation: {
      lineItems: [
        {
          title: 'Research 811 Call and Review',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 1,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Drilling (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 1150,
          dollarsMilage: 0,
        },
        {
          title: 'Field Testing (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 100,
          dollarsMilage: 0,
        },
        {
          title: 'Design Existing Arch. As-Builts',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Foundation Plan',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Redlines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Form and Rebar',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Acceptance Letter',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    mechanical: {
      lineItems: [
        {
          title: 'Calculation Heat Load',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Mechanical Layout',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Equipment Selection',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mechanical Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mecahnical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    plumbing: {
      lineItems: [
        {
          title: 'Calculate Plumbing',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary / Water Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Gas Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Gas',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary / Water / Gas Diagram',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Plumbing Schedule and Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments-1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    structural: {
      lineItems: [
        {
          title: 'Design Structural Main Frame',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Roof ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Wall',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Framing Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Section Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
  },
  'restaurant remodel': {
    admin: {
      lineItems: [
        {
          title: 'Construction Coordination',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    architecture: {
      lineItems: [
        {
          title: 'Research Existing Conditions',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Floor Plan Layout (Assume 1 change -  8 hours)',
          hoursPE: 4,
          hoursEIT: 4,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Elevations',
          hoursPE: 4,
          hoursEIT: 8,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Sections',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Floor Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Schedules',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sections and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Handicap Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 0,
          hoursEIT: 1,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 2,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    civil: {
      lineItems: [
        {
          title: 'Make 1 Site Visit',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Gas Budget',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 20,
        },
        {
          title: 'Attend Kick-Off Meeting (Assume - 1)',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Perform Topographic Survey',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Domestic and Fire Service Water to Building',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary Sewer Line and Profile',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Detention',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Cover',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet General Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Survey',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Demolition',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Site and Dimension Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water & Sanitary',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Grading Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Erosion',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet DAM ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines for 95% Plans',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Owner Review Comments and Make Changes for 100% Plans',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    electrical: {
      lineItems: [
        {
          title: 'Research Existing Electrical',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Calculations (Fault Current, Comm Check, Etc)',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Power Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Lighting Layout',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Power Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Lighting Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Panel and Riser Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Electrical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    foundation: {
      lineItems: [
        {
          title: 'Research 811 Call and Review',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 1,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Drilling (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 1150,
          dollarsMilage: 0,
        },
        {
          title: 'Field Testing (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 100,
          dollarsMilage: 0,
        },
        {
          title: 'Design Existing Arch. As-Builts',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Foundation Plan',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Redlines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Form and Rebar',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Acceptance Letter',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    mechanical: {
      lineItems: [
        {
          title: 'Calculation Heat Load',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Mechanical Layout',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Equipment Selection',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mechanical Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mecahnical Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    plumbing: {
      lineItems: [
        {
          title: 'Calculate Plumbing',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary / Water Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Gas Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Gas',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary / Water / Gas Diagram',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Plumbing Schedule and Notes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments-1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    structural: {
      lineItems: [
        {
          title: 'Design Structural Main Frame',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Roof ',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Wall',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Framing Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Section Elevations',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Notes and Details',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
  },
  'large scope': {
    admin: {
      lineItems: [
        {
          title: 'Construction Coordination',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    architecture: {
      lineItems: [
        {
          title: 'Research Existing Conditions',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Floor Plan Layout (Assume 1 change -  8 hours)',
          hoursPE: 8,
          hoursEIT: 4,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Elevations',
          hoursPE: 8,
          hoursEIT: 8,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Arch Sections',
          hoursPE: 4,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Roof Plan',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Floor Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Dimension Plan',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Schedules',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Elevations',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Plan',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sections and Details',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Handicap Notes and Details',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 4,
          hoursEIT: 2,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 3,
          hoursEIT: 1,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments and Make Changes',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 3,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 2,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    civil: {
      lineItems: [
        {
          title: 'Make 1 Site Visit',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Gas Budget',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 100,
        },
        {
          title: 'Attend Kick-Off Meeting (Assume - 1)',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Perform Topographic Survey',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Domestic and Fire Service Water to Building',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary Sewer Line and Profile',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Detention',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Cover',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet General Notes',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Survey',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Demolition',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Site and Dimension Plan',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water & Sanitary',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Grading Plan',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Erosion',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet DAM ',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines for 95% Plans',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Owner Review Comments and Make Changes for 100% Plans',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    electrical: {
      lineItems: [
        {
          title: 'Research Existing Electrical',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Calculations (Fault Current, Comm Check, Etc)',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Power Layout',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Lighting Layout',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Power Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Lighting Plan',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Panel and Riser Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Electrical Notes and Details',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    foundation: {
      lineItems: [
        {
          title: 'Research 811 Call and Review',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 1,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Drilling (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 2150,
          dollarsMilage: 0,
        },
        {
          title: 'Field Testing (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 200,
          dollarsMilage: 0,
        },
        {
          title: 'Design Existing Arch. As-Builts',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Foundation Plan',
          hoursPE: 16,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Notes and Details',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Redlines',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Form and Rebar',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Inspection Acceptance Letter',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    mechanical: {
      lineItems: [
        {
          title: 'Calculation Heat Load',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Mechanical Layout',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Equipment Selection',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mechanical Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Mecahnical Notes and Details',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    plumbing: {
      lineItems: [
        {
          title: 'Calculate Plumbing',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Sanitary / Water Layout',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Gas Layout',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Water',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Gas',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Sanitary / Water / Gas Diagram',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Plumbing Schedule and Notes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'That 1 Detail that is always a pain to make',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments-1 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    structural: {
      lineItems: [
        {
          title: 'Design Structural Main Frame',
          hoursPE: 8,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Roof ',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design Structural Wall',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Plan',
          hoursPE: 4,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Roof Framing Plan',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Section Elevations',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Sheet Structural Notes and Details',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review and Correct Red Lines',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 1',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 1 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Review City Comments 2 and Make Changes',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Miscellaneious Coordination (Owner, Utility, City) - Assume 2',
          hoursPE: 2,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
  },
  'matts scope': {
    civil: {
      lineItems: [
        {
          title: 'Design Domestic and Fire Service Water to Building',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Coordinate Collating of Plans, Printing, and Submit to City - 2',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'General Kick-Off / Project SetUp / Research',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'General Kick-Off / Project SetUp / Research',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Survey Coordination',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Field Schematic Deign Site Layout (Genera Line Work, Hatching, Etc.)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Deisgn Utility Layout (Water, Sanitary, Storm)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Sheet - Site Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Sheet - Utility Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design Grading (Civil #D Surface)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design Detention Basin (Size Basin, Hydraflow, Outlet)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD  Design Utility (Storm / Sanitary Plan & Profile)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Cover',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - General Notes',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Survey',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Demolition Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Site Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Utility Plan (Water, Sanitary, Sotrm)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Grading Sheet',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Erosion Control Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Drainage Area Map Sheet',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Drainage Calculations',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Details',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Landscape Sheet',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Submission - Submit to City ',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 1 - Review and UPdate',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 2  - Review and UPdate',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Coordination - Coordination with Owner',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    foundation: {
      lineItems: [
        {
          title: 'General Kick-Off / Project Set Up /  Research / 811',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Drilling (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Field Testing (Mid-Tex)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Design (General Linework, Hatching, Etc...)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Foundation Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Notes & Detials',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CA - Inspection (Form and Rebar)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CA - Letter of Accpetance',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Coordiation - Coordination with Owner',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    architecture: {
      lineItems: [
        {
          title: 'General Kick-Off / Project Set Up /  Research / 811',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design- Floor Plan Layout',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Architectural Elevation',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic(Review, Redlines, Submission, Comment  Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Reflected Ceiling & Roof Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Architectural Elevations',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Schedule Selections',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Floor Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Dimension Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Architectural Schedules',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Architectural Elevations',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Reflected Ceiling & Roof Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Architectural Sections',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Notes and Detials',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Submission - Submit to City',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 1 - Review & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 2 - Review & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Coordinatin - Coordination with Owner',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    electrical: {
      lineItems: [
        {
          title: 'General Kick-Off / Project Set Up / Research',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'Schematic Design - Lighting & Power Layout (Linework, Etc...)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Sheet - Power Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Sheet - Lighting Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Equipment Selection',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'CD Design - Electrical Calculations (Service, Panel, and ComCheck)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title:
            'CD Design - Lighting, Power, and Panel Layout (Electrical Line Work)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Power Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Lighting Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Panel and Riser Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Site Lighting Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Electrical Schedules, Notes, and Detials',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Submission - Submit to City ',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 1 - Review & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 2 - Reivew & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Coordination - Coorodination with Owner',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    mechanical: {
      lineItems: [
        {
          title: 'General Kick-Off / Project Set Up / Research',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Heat Load Calculation',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Equipment Identification',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Ductwork, Zone, Mechanical Layout',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Sheet - Mechanical Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Heat Load Claculation',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Equipment Selection',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Mechanical Layout (Sizing , Location, Etc...)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Mechanical Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Schedule & Detials',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Submission - Submit to City ',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 1 - Review & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 2 - Reivew & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 2 - Reivew & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Coordination - Coordination with Owner',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    plumbing: {
      lineItems: [
        {
          title: 'Design Sanitary / Water Layout',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Santiary & Water Layout (Line Work)',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Sheet - Sanitary & Water Layout Plan',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 1,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Plumbing Calculations',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Design - Equipment Selection',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Sanitary Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Water Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Sanitary and Water Riser Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Plumbing Schedule, Notes, and Details',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Submission - Submit to City',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 1 - Review & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 2  - Review & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Coordination - Coordination with Owner',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
    structural: {
      lineItems: [
        {
          title: 'General Kick-Off / Project Set Up / Research',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design- Structural Main Frame / Roof / Wall',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Calculations',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Structural Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic Design - Structural Elevations',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Schematic (Review, Redlines, Submission, Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Structural Plan',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Structural Elevations',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD Sheet - Structural Notes and Details',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'CD (Review, Redlines, Submission Comment Correction)',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Submission - Submit to City',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 1 - Reivew & Update',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'City Comment 2 - Review & Updat',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
        {
          title: 'Coordination - Coordination with Onwer',
          hoursPE: 0,
          hoursEIT: 0,
          hoursDraftsman: 0,
          hoursAdmin: 0,
          dollarsSub: 0,
          dollarsMilage: 0,
        },
      ],
    },
  },
};
