import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeProjectState, projectListState } from '../atoms/project.atom';
import { Milestone, Project, classificationOptions } from '../models/general';
import { fetchProjectsByClient, fetchProjectsByCompany } from '../utils/api';
import { workFlowStatuses } from '../utils/misc';
import { activeCompanyState } from '../atoms/new.company.atom';
import { addDays, isAfter, isBefore, isToday } from 'date-fns';

const workFlowClasses: { [key: string]: string } = {
  initiated: 'p-1 mb-1 bg-yellow-500 text-white text-sm rounded',
  estimating: 'p-1 mb-1 bg-blue-300 text-white text-sm rounded',
  'estimate - finished': 'p-1 mb-1 bg-blue-500 text-white text-sm rounded',
  holding: 'p-1 mb-1 bg-red-300 text-white text-sm rounded',
  'closed - lost': 'p-1 mb-1 bg-red-500 text-white text-sm rounded',
  'letter agreement - sent': 'p-1 mb-1 bg-green-400 text-white text-sm rounded',
  'letter agreement - signed':
    'p-1 mb-1 bg-green-500 text-white text-sm rounded',
  red: 'bg-red-500 text-white',
};

const milestoneClasses: { [key: string]: string } = {
  'Due This Week': 'p-1 mb-1 bg-yellow-500 text-white text-sm rounded',
  'Over Due': 'p-1 mb-1 bg-red-300 text-white text-sm rounded',
  'Not Due': 'p-1 mb-1 bg-green-400 text-white text-sm rounded',
};

export function ProjectList() {
  const navigate = useNavigate();
  const activeCompany = useRecoilValue(activeCompanyState);
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [classificationFilter, setClassificationFilter] =
    useState<string>('all');
  const [searchText, setSearchText] = useState<string>('');
  const [disciplineFilter, setDisciplineFilter] = useState<boolean>(false);
  const [depositFilter, setDepositFilter] = useState<boolean>(false);
  const [closedFilter, setClosedFilter] = useState<boolean>(false);
  const [projects, setProjects] = useRecoilState(projectListState);
  const setActiveProject = useSetRecoilState(activeProjectState);

  useEffect(() => {
    const fetchCompanyData = async (companyId: string) => {
      console.log('getting company data');
      try {
        const response = await fetchProjectsByCompany(companyId);
        setProjects(response.data);
      } catch (error) {
        console.error('Failed to fetch projects', error);
      }
    };
    const fetchClientData = async (companyId: string) => {
      console.log('getting client data');
      try {
        const response = await fetchProjectsByClient(companyId);
        setProjects(response.data);
      } catch (error) {
        console.error('Failed to fetch projects', error);
      }
    };
    if (
      activeCompany &&
      activeCompany.subscriptionLevel &&
      activeCompany.subscriptionLevel !== 'Guest'
    ) {
      fetchCompanyData(activeCompany._id);
    } else if (activeCompany) {
      fetchClientData(activeCompany._id);
    }
  }, [activeCompany._id]);

  const handleClick = (project: Project) => {
    setActiveProject(project);
    navigate(`/project/${project._id}`);
  };
  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setStatusFilter(event.target.value);
  };

  const handleClassificationFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setClassificationFilter(event.target.value);
  };

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
  };

  const handleDisciplineFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDisciplineFilter(event.target.checked);
  };

  const handleDepositFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDepositFilter(event.target.checked);
  };

  const handleClosedFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setClosedFilter(event.target.checked);
  };

  const filteredProjects = projects
    ? projects.filter((project) => {
        const matchesStatus =
          statusFilter === 'all' || project.workFlowStatus === statusFilter;
        const matchesClassification =
          classificationFilter === 'all' ||
          project.classification === classificationFilter;
        const matchesSearchText =
          project.name.toLowerCase().includes(searchText.toLowerCase()) ||
          project.client.name.toLowerCase().includes(searchText.toLowerCase());
        const matchesDiscipline =
          !disciplineFilter ||
          (project.disciplines.length === 1 &&
            project.disciplines[0] === 'foundation');
        const matchesDeposit =
          !depositFilter ||
          project.workFlowStatus === 'letter agreement - signed' ||
          project.workFlowStatus === 'schematics - finished' ||
          project.workFlowStatus === 'design plans - finished' ||
          project.workFlowStatus === 'AHJ - approved';
        const showClosed =
          closedFilter || project.workFlowStatus !== 'closed - lost';

        return (
          matchesStatus &&
          matchesClassification &&
          matchesSearchText &&
          matchesDiscipline &&
          matchesDeposit &&
          showClosed
        );
      })
    : [];

  const milestoneStatus = (project: Project) => {
    const milestones: Milestone[] = project.milestones || [];

    const now = new Date();
    const fiveDaysFromNow = addDays(now, 3);

    const withinDays = milestones.filter(
      (milestone) =>
        !milestone.completionDate &&
        milestone.targetDate &&
        isAfter(milestone.targetDate, now) &&
        isBefore(milestone.targetDate, fiveDaysFromNow),
    );

    const todayOrOlder = milestones.filter(
      (milestone) =>
        !milestone.completionDate &&
        milestone.targetDate &&
        (isToday(milestone.targetDate) || isBefore(milestone.targetDate, now)),
    );
    if (todayOrOlder.length > 0) {
      return 'Over Due';
    } else if (withinDays.length > 0) {
      return 'Due This Week';
    } else {
      return 'Not Due';
    }
  };

  return (
    <div>
      <div className="my-4 pb-2 border-b-2 border-black grid grid-cols-4">
        <label className="px-5">
          <input
            type="text"
            className="rounded border border-solid border-neutral-200 px-3"
            value={searchText}
            onChange={handleSearchTextChange}
            placeholder="Search projects..."
          />
        </label>
        {/* <label>
          <input
            type="checkbox"
            checked={disciplineFilter}
            onChange={handleDisciplineFilterChange}
          />{' '}
          Foundations Only
        </label> */}
        <label>
          <input
            type="checkbox"
            checked={depositFilter}
            onChange={handleDepositFilterChange}
          />{' '}
          Jobs Needing Invoice
        </label>
        <label>
          <input
            type="checkbox"
            checked={closedFilter}
            onChange={handleClosedFilterChange}
          />{' '}
          Show Closed
        </label>
      </div>
      <div className="grid grid-cols-5 capitalize text-lg">
        <div>Name</div>
        <div>Client</div>
        <label className="px-5">
          Classification:
          <select
            value={classificationFilter}
            onChange={handleClassificationFilterChange}
            className="max-w-40"
          >
            {['all', ...classificationOptions].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
        <label className="px-5">
          Status:
          <select
            className="max-w-40"
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            {['all', ...workFlowStatuses].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
        <div className="text-center">Milestone Status</div>
      </div>
      {filteredProjects.map((project: Project) => (
        <div
          className="grid grid-cols-5 capitalize cursor-pointer hover:text-gray-500 hover:font-bold"
          onClick={() => handleClick(project)}
        >
          <div>{project.name}</div>
          <div>{project.client?.name || 'Data Missing'}</div>
          <div>{project.classification}</div>
          <div
            className={`${workFlowClasses[project.workFlowStatus || 'black']}`}
          >
            {project.workFlowStatus}
          </div>
          <div
            className={`${milestoneClasses[milestoneStatus(project) || 'black']} text-center w-44 mx-auto`}
          >
            {milestoneStatus(project)}
          </div>
        </div>
      ))}
    </div>
  );
}
