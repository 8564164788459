import React, { useState, useEffect, ChangeEvent } from "react";
import { templates } from "../utils/misc";

interface SelectItemComponentProps {
  selection: string;
  onSelectionChange: (selected: string) => void;
}

const SelectItemComponent: React.FC<SelectItemComponentProps> = ({
  selection,
  onSelectionChange,
}) => {
  const [selectedItem, setSelectedItem] = useState<string>(selection);

  useEffect(() => {
    setSelectedItem(selection);
  }, [selection]);

  const handleSelection = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedItem(newValue);
    onSelectionChange(newValue);
  };

  return (
    <div>
      <select value={selectedItem} onChange={handleSelection}>
        <option value="" disabled>
          Select an item
        </option>
        {templates.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectItemComponent;
