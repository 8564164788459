import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import letterHead from '../images/letterHead.png';
import 'react-quill/dist/quill.snow.css';
import { useOutletContext } from 'react-router-dom';
import { Project } from '../models/general';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import PhoneNumberFormatter from './PhoneNumber';
import { List } from '@mui/joy';
import {
  agreementTemaplate,
  constructionSheets,
  disciplineMap,
  formatCurrency,
  schematicSheets,
} from '../utils/misc';
import TextList from './TextList';
import { useRecoilValue } from 'recoil';
import { lOETotals } from '../atoms/project.atom';
import LetterDisplay from './HTMLPDF';
import Terms from './Exhibits/Terms';
import { pdfStyles } from '../utils/letterAgreement';
import AdditionalServices from './Exhibits/AdditionalServices';
import { activeUserState } from '../atoms/user.atom';
import { activeCompanyState } from '../atoms/new.company.atom';

const options = {
  filename: 'Letter Agreement.pdf',
  // default is `save`

  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.HIGH,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.MEDIUM,
    format: 'letter',
  },
};

const PDFTEST = ({ getHtmlContent }) => {
  const { activeProject, updateProjectList } =
    useOutletContext<OutletContext>();
  const activeUser = useRecoilValue(activeUserState);
  const activeCompany = useRecoilValue(activeCompanyState);
  const targetRef = useRef();
  const totals = useRecoilValue(lOETotals);
  function isLargeProject(): boolean {
    return (totals?.total || 0) > 10000;
  }
  const hasDiscipline = (discp: string) => {
    return activeProject.disciplines.includes(discp);
  };
  const handlePrint = () => {
    if (targetRef && targetRef.current)
      window.electron.print(targetRef.current.innerHTML);
  };
  useEffect(() => {
    if (targetRef.current && getHtmlContent) {
      getHtmlContent(targetRef.current.innerHTML);
    }
  }, []);
  function buildSheets(disciplines: string[], sheets: {}): string[] {
    const combinedArray: string[] = [];
    console.log('map', disciplineMap);
    disciplines.forEach((key) => {
      if (sheets.hasOwnProperty(key)) {
        combinedArray.push(...(sheets as any)[key]);
      }
    });
    console.log(combinedArray);
    return combinedArray;
  }

  type ListObject = { [key: string]: string[] };
  function combineAndSortLists(
    obj: ListObject,
    includedDisciplines: string[],
  ): string[] {
    const combinedArray: string[] = [];

    // Combine all the strings into a single array, filtered by included disciplines
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && includedDisciplines.includes(key)) {
        combinedArray.push(...obj[key]);
      }
    }

    // Desired order of keys
    const desiredOrder = ['cover', 'civil', 'architecture'];

    // Create a map for quick lookup of the desired order
    const orderMap: { [key: string]: number } = {};
    desiredOrder.forEach((key, index) => {
      orderMap[key] = index;
    });

    // Sort the combined array based on the desired order
    combinedArray.sort((a, b) => {
      const aKey = Object.keys(obj).find((key) => obj[key].includes(a));
      const bKey = Object.keys(obj).find((key) => obj[key].includes(b));

      const aIndex = aKey ? orderMap[aKey] : Number.MAX_SAFE_INTEGER;
      const bIndex = bKey ? orderMap[bKey] : Number.MAX_SAFE_INTEGER;

      return aIndex - bIndex;
    });

    return combinedArray;
  }

  return (
    <div>
      <button
        className="rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto"
        onClick={() => generatePDF(targetRef, options)}
      >
        Download PDF
      </button>
      <button
        className="rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto"
        onClick={() => handlePrint()}
      >
        Print PDF
      </button>
      <div id="printthis" ref={targetRef}>
        {/* <img
          className="w-100 inline"
          src={letterHead}
          alt="Abacus Engineering Inc."
        ></img> */}
        <h1 style={pdfStyles.title}>
          Engagement Agreement - {activeProject.address}
        </h1>
        <br />

        <br />
        <p>{activeProject.client?.name}</p>
        <p>{activeProject.client?.address}</p>
        <p>{activeProject.client?.email}</p>
        <p>
          <PhoneNumberFormatter phoneNumber={activeProject.client?.phone} />
        </p>
        <br />
        <p>
          Dear {activeProject.client?.owner?.firstName}{' '}
          {activeProject.client?.owner?.lastName},
        </p>
        <br />
        <p>
          It is our understanding that you are looking for{' '}
          {activeProject.disciplines.map((i) => {
            return i + ', ';
          })}{' '}
          drawings for the proposed project at {activeProject.address}.
        </p>
        <br />
        <p>
          The scope set forth herein defines the work to be performed by Abacus
          Engineering Inc. (Engineer) in completing this project. Both{' '}
          {activeProject.client?.name} (Client) and Engineer have attempted to
          clearly define the work to be performed and address the needs of the
          Project. Under this scope, “Engineer” is expanded to include any
          sub-consultant, employed, or contracted by the Engineer.
        </p>
        <br />
        <h3 style={pdfStyles.h3}>GENERAL SCOPE DEVELOPMENT ASSUMPTIONS</h3>
        <br />
        <h4 style={pdfStyles.h4}>
          Engineer’s Assumptions for the development of the scope and fee
          include:
        </h4>
        <TextList
          items={agreementTemaplate.generalScopes}
          style={pdfStyles.ul}
        />
        <br />
        <h4 style={pdfStyles.h4}>Client Responsibilities</h4>
        <TextList
          items={agreementTemaplate.clientResponsibilities}
          style={pdfStyles.letteredList}
        />
        <br />
        <h3 style={pdfStyles.h3}>SCOPE OF SERVICES</h3>
        <br />
        <h4 style={pdfStyles.h4}>
          Scope of Design Phase Services and Deliverables
        </h4>
        <br />
        <p style={pdfStyles.bold}>Site Visit</p>
        <TextList
          items={[
            'Engineer may visit the site one (1) time to become familiar with the site, take pictures, and to confirm analysis details, as necessary.',
          ]}
          style={pdfStyles.numberList}
        />
        {hasDiscipline('civil') && (
          <>
            <br />
            <p style={pdfStyles.bold}>Topographic Survey</p>
            <TextList
              items={[
                'The Engineer shall coordinate with a Registered Land Surveying Firm on behalf of Client to obtain a topographic survey of the areas if needed to support the design process.',
              ]}
              style={pdfStyles.numberList}
            />
            <br />
          </>
        )}
        {hasDiscipline('foundation') && (
          <>
            <p style={pdfStyles.bold}>Soil Conditions</p>
            <TextList
              items={[
                'The Engineer shall coordinate with a reputable company on behalf of Client to perform the soil samples needed.',
              ]}
              style={pdfStyles.numberList}
            />
          </>
        )}
        {isLargeProject() && (
          <>
            <br />
            <p style={pdfStyles.bold}>Develop Schematic Design</p>
            <TextList
              items={[
                'Engineer may visit the site one (1) time to become familiar with the site, take pictures, and to confirm analysis details, as necessary.',
                'The Concept Drawing Plan Set shall consist of the following sheets:',
              ]}
              style={pdfStyles.ul}
            />
            <div>
              <TextList
                items={combineAndSortLists(schematicSheets, [
                  'cover',
                  ...activeProject.disciplines,
                ])}
                style={pdfStyles.numberList}
              />
              <br />
              <p style={pdfStyles.italic}>
                Deliverables: Schematic Design Plan Set
              </p>
            </div>
          </>
        )}

        <br />
        <p style={pdfStyles.bold}>Develop Construction Documents</p>
        <TextList
          items={[
            'The Engineer shall develop Construction Documents and submit them to the owner for their review and submission to the City. The Engineer shall answer up to two (2) rounds of comments from the Owner.',
            'The Construction Drawing Plan Set shall consist of the following sheets:',
          ]}
          style={pdfStyles.ul}
        />
        <div>
          <TextList
            items={combineAndSortLists(constructionSheets, [
              'cover',
              ...activeProject.disciplines,
            ])}
            style={pdfStyles.numberList}
          />
        </div>
        <TextList
          items={[
            'The Engineer shall submit a sealed set to the Owner for their submission to the AHJ. The Engineer shall review and address up to two (2) rounds of comments from the AHJ. If necessary, the Engineer shall set up a meeting with the AHJ to discuss review comments. Addressing errors or omissions shall not count against the rounds of comments.',
          ]}
          style={pdfStyles.ul}
        />
        <br />
        <p style={pdfStyles.italic}>
          Deliverables: 100% Construction Documents Plan Set
        </p>
        <br />
        <h4 style={pdfStyles.h4}>
          Scope of Construction Phase Services and Deliverables
        </h4>
        <br />
        <p style={pdfStyles.bold}>Construction Administration</p>
        <TextList
          items={[
            'Request For Information (RFI) - Answer up to two (2) RFIs that may arise regarding the plans, specifications, and/or field conditions. Any questions due to errors in the plans shall NOT count against the owner’s RFI count.',
            'Review Submittals - Review up to three (3) submittals from the contractor and verify that they are in general conformance with the plans and specifications.',
          ]}
          style={pdfStyles.letteredList}
        />
        {hasDiscipline('foundation') && (
          <>
            <h2 style={pdfStyles.bold}>Foundation Inspection Documents</h2>
            <p>
              This scope of work includes up to one (1) site visits at the
              contractor’s request to review and document the concrete form and
              rebar placement. If passing, the Engineer shall write a Letter of
              Acceptance.
            </p>
            <br />
            <h2 style={pdfStyles.bold}>Testing Review</h2>
            <p>
              The Engineer shall review and document all testing that is
              performed by a 3rd party testing firm that is to be retained by
              the owner.
            </p>
          </>
        )}
        <br />
        <h2 style={pdfStyles.h2}>Additional Service/Exclusions</h2>
        <p style={pdfStyles.italic}>
          See Exhibit B for the additional services and exclusions that are not
          included in this Scope of Work or Fee. However, the Engineer can
          provide these services, if needed, upon the Owner’s written request.
          Any additional amounts paid to the Engineer as a result of any
          material change to the Scope of Services for the Project shall be
          agreed upon in writing by both parties before the services are
          performed.
        </p>
        <br />
        <h2 style={pdfStyles.h2}>Contract Documents</h2>
        <p style={pdfStyles.italic}>
          Upon execution by both parties, this proposal shall become a
          contractual agreement between the parties effective on the date
          accepted by the last of the parties to sign, and said agreement shall
          be governed by the following documents, each of which is accepted by
          the parties and incorporated herein for all purposes:
        </p>
        <TextList
          items={[
            'This Letter Agreement',
            'Exhibit A - Terms and Conditions',
            'Exhibit B – Additional Services and Exclusions',
          ]}
          style={pdfStyles.numberList}
        />
        <br />

        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

function StaticAgreement({ getHtmlContent }) {
  return (
    <>
      <div className="bg-white p-6 rounded-lg shadow-lg mt-2">
        <PDFTEST getHtmlContent={getHtmlContent} />
      </div>
    </>
  );
}

export default StaticAgreement;
