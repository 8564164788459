import React, { useState, useEffect, useRef } from 'react';
import { sendMessage, fetchMessages } from '../utils/api';
import { format } from 'date-fns';
import { truncateMessage } from '../utils/misc';

const MessageModal: React.FC<{
  projectId: string;
  thread: any;
  onClose: () => void;
}> = ({ projectId, thread, onClose }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [sendEmail, setSendEmail] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = 100;
  useEffect(() => {
    const loadMessages = async () => {
      try {
        const response = await fetchMessages(
          projectId,
          thread._id,
          page,
          pageSize,
        );
        console.log('mess', response.data);
        if (response.data.messages.length <= pageSize) {
          setHasMore(false);
        }
        setMessages((prevMessages) => [...response.data.messages]);
      } catch {
        alert('Messages Failed to Load. Please try again.');
      }
    };
    loadMessages();
  }, [thread._id, page]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async () => {
    try {
      const msgResponse = await sendMessage(
        projectId,
        thread._id,
        newMessage,
        sendEmail,
        false,
      );
      setNewMessage('');
    } catch {
      alert('Message Failed to send. Please try again.');
    }
    try {
      const updatedResponse = await fetchMessages(projectId, thread._id);

      setMessages(updatedResponse.data.messages);
      setPage(1);
      setSendEmail(false);
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    } catch {
      alert('Failed to load new messages. Please reload the page.');
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0 && hasMore) {
      // console.log('implement support for more than 100 messages');
      setPage((prevPage) => prevPage + 1);
    }
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendEmail(event.target.checked);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50  flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-6 w-3/4 relative">
        <h2 className="text-2xl font-bold mb-4">{thread.title}</h2>
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <div
          className="messages overflow-y-scroll max-h-60 mb-4"
          onScroll={handleScroll}
          ref={messagesContainerRef}
        >
          {messages &&
            messages.length > 0 &&
            messages.map((msg) => (
              <div
                key={msg._id}
                className="mb-2 p-2 bg-gray-100 rounded-lg shadow-sm flex flex-col"
              >
                <span className="font-bold text-sm text-gray-700">
                  {msg.author.firstName} {msg.author.lastName}
                </span>
                <span className="text-xs text-gray-500">
                  {format(new Date(msg.createdAt), 'MMM d, yyyy, h:mm a')}
                </span>
                <p className="text-gray-800">
                  {truncateMessage(msg.content, 1000)}
                </p>
              </div>
            ))}
        </div>
        <textarea
          className="w-full p-2 border rounded mb-2"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleSendMessage}
        >
          Send
        </button>
        <button
          className="mt-4 mx-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          onClick={onClose}
        >
          Close
        </button>
        <label>
          <input
            type="checkbox"
            checked={sendEmail}
            onChange={handleCheckboxChange}
          />{' '}
          Send with email notifications
        </label>
      </div>
    </div>
  );
};

export default MessageModal;
