import { Input } from "@mui/joy";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { lOEItemListState } from "../atoms/project.atom";
import { LOEItemI } from "../models/general";
import { hourlyRates, calculateItemTotal } from "../utils/misc";

interface filterProps {
  item: LOEItemI;
  index: number;
  onRemove: Function;
  onChange: Function;
  styles?: {};
}

export default function LOEItem(props: filterProps) {
  let { item, index, onChange, onRemove } = props;
  const [lOEItemList, setLOEItemList] = useRecoilState(lOEItemListState);
  const [title, setTitle] = useState(item.title);
  const [hoursPE, setHoursPE] = useState(item.hoursPE);
  const [hoursEIT, setHoursEIT] = useState(item.hoursEIT);
  const [hoursDraftsman, setHoursDraftsman] = useState(item.hoursDraftsman);
  const [hoursAdmin, setHoursAdmin] = useState(item.hoursAdmin);
  const [dollarsSub, setDollarsSub] = useState(item.dollarsSub);
  const [dollarsMilage, setDollarsMilage] = useState(item.dollarsMilage);
  const [total, setTotal] = useState(calculateItemTotal(item));
  const [shouldDisplay, setShouldDisplay] = useState(true);

  const handleBlur = () => {
    item = {
      discipline: item.discipline,
      title,
      hoursPE,
      hoursEIT,
      hoursDraftsman,
      hoursAdmin,
      dollarsSub,
      dollarsMilage,
    };
    // console.log(item);
    onChange(index, item);
    setTotal(calculateItemTotal(item));
  };
  const handleRemove = () => {
    onRemove(index);
    setShouldDisplay(false);
  };
  if (shouldDisplay) {
    return (
      <>
        <div className="col-span-4">
          <Input
            type="text"
            value={title}
            onBlur={handleBlur}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div className="col-span-1 px-2">${total}</div>
        <div className="col-span-1 px-2">
          <Input
            type="numbers"
            endDecorator="hrs"
            value={hoursPE}
            onBlur={handleBlur}
            onChange={(event) => setHoursPE(+event.target.value)}
          />
        </div>
        <div className="col-span-1 px-2">
          <Input
            type="number"
            endDecorator="hrs"
            value={hoursEIT}
            onBlur={handleBlur}
            onChange={(event) => setHoursEIT(+event.target.value)}
          />
        </div>
        <div className="col-span-1 px-2">
          <Input
            type="number"
            endDecorator="hrs"
            value={hoursDraftsman}
            onBlur={handleBlur}
            onChange={(event) => setHoursDraftsman(+event.target.value)}
          />
        </div>
        <div className="col-span-1 px-2">
          <Input
            type="number"
            startDecorator="$"
            value={dollarsSub}
            onBlur={handleBlur}
            onChange={(event) => setDollarsSub(+event.target.value)}
          />
        </div>
        <div className="col-span-1 px-2">
          <Input
            type="number"
            startDecorator="$"
            value={dollarsMilage}
            onBlur={handleBlur}
            onChange={(event) => setDollarsMilage(+event.target.value)}
          />
        </div>
        <div
          className="rounded bg-red-500  text-white w-8 h-8 mx-4 font-small cursor-pointer text-center"
          onClick={handleRemove}
        >
          X
        </div>
      </>
    );
  } else {
    return <></>;
  }
}

function replaceItemAtIndex(
  arr: LOEItemI[],
  index: number,
  newValue: LOEItemI
) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}
