export const letterAgreement = {
  summary: '',
  generalScope: {
    heading: '',
  },
};

export const pdfStyles = {
  bold: {
    padding: '0 0',
    'font-size': '1em',
    'font-weight': 'bold',
  },
  italic: {
    padding: '0 0',
    'font-size': '1em',
    'font-style': 'italic',
  },
  title: {
    'text-align': 'center',
    padding: '20px 0',
    'border-bottom': '1px solid #000',
    'font-size': '2.75em',
    'font-weight': 'bold',
  },
  exhibitSmallTitle: {
    'text-align': 'center',
    padding: '20px 0 0 0',
    'font-size': '2.00em',
    'font-weight': 'bold',
  },
  smallTitle: {
    'text-align': 'center',
    padding: '0',
    'font-size': '1.50em',
    'font-weight': 'bold',
  },
  h1: {
    padding: '0 0',
    'font-size': '2.5em',
    'font-weight': 'bold',
  },
  h2: {
    padding: '0 0',
    'font-size': '2.0em',
    'font-weight': 'normal',
  },
  h3: {
    padding: '0 0',
    'font-size': '1.5em',
    'font-weight': 'bold',
  },
  h4: {
    padding: '0 0',
    'font-size': '1.5em',
    'font-weight': 'normal',
  },
  p: {
    padding: '0 0',
    'font-size': '1.5em',
    'font-weight': 'normal',
  },
  ul: {
    display: 'block',
    'list-style-type': 'disc',
    'margin-block-start': '1em',
    'margin-block-end': '1em',
    'margin-inline-start': '0px',
    'margin-inline-end': '0px',
    'padding-inline-start': '40px',
    'unicode-bidi': 'isolate',
  },
  numberList: {
    display: 'block',
    'list-style-type': 'decimal',
    'margin-block-start': '1em',
    'margin-block-end': '1em',
    'margin-inline-start': '0px',
    'margin-inline-end': '0px',
    'padding-inline-start': '40px',
    'unicode-bidi': 'isolate',
  },
  letteredList: {
    display: 'block',
    'list-style-type': 'upper-alpha',
    'margin-block-start': '1em',
    'margin-block-end': '1em',
    'margin-inline-start': '0px',
    'margin-inline-end': '0px',
    'padding-inline-start': '40px',
    'unicode-bidi': 'isolate',
  },
  priceRow: {
    display: 'flex',
    'justify-content': 'space-between',
    padding: '0 1.5rem',
  },
  priceRowSum: {
    display: 'flex',
    'justify-content': 'space-between',
    padding: '1.5rem',
    'border-top-width': '4px',
    'border-color': 'black',
    'font-weight': 'bold',
  },
  rowLeft: {
    'text-align': 'left',
    margin: 0,
  },
  rowRight: {
    'text-align': 'right',
    margin: 0,
  },
};
