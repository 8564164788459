import { Form } from '../components/forms/Form';
import { ProjectList } from '../components/ProjectList';

const Projects = () => {
  return (
    <div className=" bg-white p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl text-bold">Projects</h2>
      <ProjectList />
    </div>
  );
};
export default Projects;
