import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { Combobox, Listbox, Switch } from '@headlessui/react';
import {
  LOETable,
  LOEtemplates,
  classNames,
  disciplines,
} from '../../utils/misc';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import {
  activeProjectState,
  lOEItemListState,
  lOETotals,
  projectListState,
} from '../../atoms/project.atom';
import {
  activeCompanyState,
  companyListState,
} from '../../atoms/new.company.atom';
import { useNavigate } from 'react-router-dom';
import SelectItemComponent from '../Selector';
import { LOEItemI, Project, classificationOptions } from '../../models/general';
import { createProject, fetchCompanies } from '../../utils/api';
import Modal from '../modal';
import CreateClientCompanyForm from './CreateClientCompanyForm';
import { activeUserState } from '../../atoms/user.atom';
import { addDays } from 'date-fns';

export const Form = () => {
  const navigate = useNavigate();
  const activeUser = useRecoilValue(activeUserState);
  const company = useRecoilValue(activeCompanyState);
  const [activeProject, setActiveProject] = useRecoilState(activeProjectState);
  const [projectList, setProjectList] = useRecoilState(projectListState);
  const setLOEItemList = useSetRecoilState(lOEItemListState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clients, setClients] = useRecoilState(companyListState);
  const workItems: LOEItemI[] = [];
  let disciplines2: string[] = [];
  const [templateSelection, setTemplateSelection] = useState<string>('');
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      classification: '',
      description: '',
      isUnplatted: false,
      unplattedAddress: '',
      address: '',
      disciplines: [],
      client: '',
      shouldBillOwner: false,
      owner: '',
      workFlowStatus: 'initiated',
    },
  });

  const formStatus = watch();

  const handleSelectionChange = (newSelection: string) => {
    setTemplateSelection(newSelection);
  };

  const updateProjectList = (project: Project) => {
    if (!('_id' in project)) return;
    console.log('SAVING LIST');
    const items = [...projectList];
    const existingItemIndex = items.findIndex(
      (item) => item._id === project._id,
    );
    console.log('SAVING LIST', existingItemIndex, project._id);
    if (existingItemIndex !== -1) {
      // Update existing item
      const updatedItems = [...items];
      updatedItems[existingItemIndex] = project;
      setProjectList(updatedItems);
    } else {
      setProjectList([...items, project]);
    }
  };

  const [query, setQuery] = useState('');
  const filteredclients =
    query === ''
      ? clients
      : clients.filter((client) => {
          return (
            client.name.toLowerCase().includes(query.toLowerCase()) ||
            client.email.toLowerCase().includes(query.toLowerCase())
          );
        });

  useEffect(() => {
    const getData = async () => {
      updateProjectList(activeProject);
      try {
        const resp = await fetchCompanies();
        setClients(resp.data);
      } catch {}
    };
    getData();
  }, [activeProject]);

  const onSubmit = async (values: any) => {
    let loeTable = LOETable;
    console.log('errs', errors);
    const _id = uuidv4();
    const data = {
      form: values,
    };
    // send the POST request to back end
    // Push request result into projectList
    // add parse-location

    // Creating LOE's from template
    if (templateSelection.length > 0) {
      loeTable = (LOEtemplates as any)[templateSelection];
    }
    disciplines2 = data.form.disciplines;
    disciplines2.forEach((discipline) => {
      (loeTable as any)[discipline].lineItems.forEach((loeItem: LOEItemI) => {
        const newItem = {
          discipline,
          ...loeItem,
        };
        workItems.push(newItem);
      });
    });
    await setLOEItemList(workItems);
    console.log(data.form);
    setActiveProject({
      _id: _id,
      ...data.form,
      company: company._id,
      loeEstimate: workItems,
    });
    try {
      const newProject = {
        ...data.form,
        company: company._id,
        lead: activeUser._id,
        loeEstimate: workItems,
        milestones: [
          {
            title: 'Estimate Completed',
            targetDate: addDays(new Date(), 7),
          },
        ],
      };
      const response = await createProject(newProject);
      console.log('SAVED Project', response.data);
      setProjectList((oldList) => [...oldList, response.data]);
    } catch (error) {
      alert('Project creation failed', error);
    }
    setActiveProject({} as Project);
    setLOEItemList([]);
    reset((values = {}));
    navigate(`/projectList`);
  };

  return (
    <div>
      <h4 className="text-gray-600 text-2xl font-semibold mb-4">
        Create a New Project
      </h4>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <CreateClientCompanyForm onClose={() => setIsModalOpen(false)} />
      </Modal>
      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className=" grid grid-cols-2 bg-white p-6 rounded-lg shadow-lg">
          <div id="Name-address" className="p-2">
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-600">
                Project Name
              </label>
              <div className="mt-1">
                <input
                  type="input"
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Ex. Waco Mustang Mall"
                  {...register('name', { required: true })}
                />
                {errors.name && <p className="error">{errors.name.message}</p>}
              </div>
            </div>
            <p>{errors.name?.message}</p>
            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Switch.Group as="div" className="flex items-center pb-2">
                  <Switch
                    checked={value}
                    onChange={onChange}
                    className={classNames(
                      value ? 'bg-blue-500' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        value ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Switch.Label as="span" className="ml-3 text-sm">
                    <span className="font-medium text-gray-600">
                      Project Location is isUnplatted
                    </span>
                  </Switch.Label>
                </Switch.Group>
              )}
              name="isUnplatted"
            />
            {formStatus.isUnplatted && (
              <div className="pb-2">
                <label className="block text-sm font-medium leading-6 text-gray-600">
                  Project Location
                </label>
                <div className="mt-1">
                  <input
                    type="input"
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    placeholder="Ex. Enter FM Road Number or ..."
                    {...register('unplattedAddress', {
                      required: formStatus.isUnplatted,
                      disabled: !formStatus.isUnplatted,
                    })}
                  />
                </div>
              </div>
            )}
            {!formStatus.isUnplatted && (
              <div className="pb-2">
                <label className="block text-sm font-medium leading-6 text-gray-600">
                  Project Address
                </label>
                <div className="mt-1">
                  <input
                    type="input"
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    placeholder="Ex. 123 Main Waco, TX 76707"
                    {...register('address', {
                      required: !formStatus.isUnplatted,
                    })}
                  />
                </div>
              </div>
            )}
            <div className="pb-2">
              {/* This doesn't filter properly yet. But that is okay it will need to connect with a backend call. */}
              <label className="block text-sm font-medium leading-6 text-gray-600">
                Client
              </label>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Combobox value={value} onChange={onChange}>
                    <Combobox.Input
                      className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      onChange={(event) => setQuery(event.target.value)}
                      displayValue={(client) => client.name}
                    />
                    <Combobox.Options>
                      {filteredclients.map((cls) => (
                        <Combobox.Option
                          key={cls._id}
                          value={cls}
                          style={{
                            textTransform: 'capitalize',
                            borderBottom: '1px solid',
                          }}
                        >
                          {cls.name}
                          <br></br> {cls.address}
                        </Combobox.Option>
                      ))}
                      <Combobox.Option
                        key="newBusiness"
                        value="newBusiness"
                        style={{
                          textTransform: 'capitalize',
                          borderBottom: '1px solid',
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => setIsModalOpen(true)}
                        >
                          Create Client Company
                        </button>
                      </Combobox.Option>
                    </Combobox.Options>
                  </Combobox>
                )}
                name="client"
              />
            </div>
            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={value}
                    onChange={onChange}
                    className={classNames(
                      value ? 'bg-blue-500' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        value ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <Switch.Label as="span" className="ml-3 text-sm">
                    <span className="font-medium text-gray-600">
                      Invoices should be sent to the Owner?
                    </span>
                  </Switch.Label>
                </Switch.Group>
              )}
              name="shouldBillOwner"
            />
            {formStatus.shouldBillOwner && (
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-600">
                  Owner
                </label>
                <div className="mt-1">
                  <input
                    type="input"
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    placeholder="Ex. Owner Name"
                    {...register('owner', { required: true })}
                  />
                </div>
              </div>
            )}
          </div>
          <div id="Details" className="p-2">
            <div className="pb-2">
              <label className="block text-sm font-medium leading-6 text-gray-600">
                Project Classification
              </label>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Listbox value={value} onChange={onChange}>
                    <Listbox.Button
                      className="rounded bg-blue-500 px-4 py-2 text-white font-medium"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {value || 'Select One'}
                    </Listbox.Button>
                    <Listbox.Options>
                      {classificationOptions.map((cls) => (
                        <Listbox.Option
                          key={cls}
                          value={cls}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {cls}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Listbox>
                )}
                name="classification"
              />
            </div>

            <div className="pb-2">
              <label className="block text-sm font-medium leading-6 text-gray-600">
                Description
              </label>
              <div className="mt-1">
                <input
                  type="input"
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                  placeholder="Ex. Key details of the project: What was requested, previous projects to reference etc..."
                  {...register('description', { required: true })}
                />
              </div>
            </div>

            <div className="pb-2">
              <label className="block text-sm font-medium leading-6 text-gray-600">
                Included Disciplines
              </label>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Listbox value={value} onChange={onChange} multiple>
                    {value.length > 0 &&
                      value.map((val) => (
                        <Listbox.Button className="rounded bg-blue-500 px-4 py-2 text-white font-medium capitalize cursor-pointer">
                          {val}
                        </Listbox.Button>
                      ))}
                    {value.length < 1 && (
                      <Listbox.Button className="rounded bg-blue-500 px-4 py-2 text-white font-medium capitalize cursor-pointer">
                        'Select Disciplines'
                      </Listbox.Button>
                    )}
                    <Listbox.Options>
                      {disciplines.map((cls) => (
                        <Listbox.Option
                          key={cls}
                          value={cls}
                          className="capitalize cursor-pointer"
                        >
                          {cls}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Listbox>
                )}
                name="disciplines"
              />
            </div>
          </div>
          <div className="col-span-2 grid content-center mt-2">
            <label className="block text-sm font-medium leading-6 text-gray-600">
              Start From a Template?
            </label>
            <SelectItemComponent
              selection={templateSelection}
              onSelectionChange={handleSelectionChange}
            />
          </div>
          <div className="col-span-2 grid content-center mt-2">
            <button
              type="submit"
              className="rounded bg-blue-500 px-4 py-2 text-white w-60 font-medium mx-auto"
            >
              Save & Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
