// src/renderer/components/ClickToSign.tsx

import React from 'react';

interface ClickToSignProps {
  appliedSignature: string;
  onSign: () => void;
}

const ClickToSign: React.FC<ClickToSignProps> = ({
  appliedSignature,
  onSign,
}) => {
  return (
    <div className="click-to-sign">
      {appliedSignature ? (
        <div>
          <img
            src={appliedSignature}
            alt="Applied Signature"
            className="border-2 border-gray-300 rounded max-h-20"
          />
        </div>
      ) : (
        <button
          onClick={onSign}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Click to Sign
        </button>
      )}
    </div>
  );
};

export default ClickToSign;
