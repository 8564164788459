// src/../components/ConstructionCostEstimate.tsx

import React, { useState } from 'react';
import { formatCurrency } from '../utils/misc';

const ConstructionCostEstimate = () => {
  const [totalCost, setTotalCost] = useState<number>(0);
  const [formData, setFormData] = useState({
    squareFoot: 1,
    dollarPerSquareFoot: 1,
    lumpSumCosts: 0,
  });
  const calculateTotalCost = () => {
    const cost =
      formData.squareFoot * formData.dollarPerSquareFoot +
      formData.lumpSumCosts * 1;
    setTotalCost(cost);
  };

  const calculatePercentage = (percentage: number) => {
    return (totalCost * percentage) / 100;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    calculateTotalCost();
  };

  return (
    <div className="container mx-auto my-4 p-6 ">
      <h2 className="text-2xl font-semibold mb-4">Cost Estimate</h2>
      <div className="mb-4">
        <label className="block text-white-700">Square Foot</label>
        <input
          type="number"
          name="squareFoot"
          value={formData.squareFoot}
          onChange={handleChange}
          onBlur={handleChange}
          className="w-full text-gray-700 p-0 border rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-white-700">Dollar Per Square Foot</label>
        <input
          type="number"
          name="dollarPerSquareFoot"
          value={formData.dollarPerSquareFoot}
          onChange={handleChange}
          onBlur={handleChange}
          className="w-full text-gray-700 p-0 border rounded"
        />
      </div>
      <div className="mb-4">
        <label className="block text-white-700">Lump Sum Costs</label>
        <input
          type="number"
          name="lumpSumCosts"
          value={formData.lumpSumCosts}
          onChange={handleChange}
          onBlur={handleChange}
          className="w-full text-gray-700 p-0 border rounded"
        />
      </div>

      <div className="mt-4">
        <h3 className="text-xl font-semibold">Total Construction Cost:</h3>
        <p className="text-lg">{formatCurrency(totalCost)}</p>
      </div>
      <div className="mt-4">
        <h3 className="text-xl font-semibold">
          Industry Standard Fee Targets:
        </h3>
        <p className="text-lg">2% : {formatCurrency(calculatePercentage(2))}</p>
        <p className="text-lg">4% : {formatCurrency(calculatePercentage(4))}</p>
        <p className="text-lg">6% : {formatCurrency(calculatePercentage(6))}</p>
        <p className="text-lg">8% : {formatCurrency(calculatePercentage(8))}</p>
      </div>
    </div>
  );
};

export default ConstructionCostEstimate;
