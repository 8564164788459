import React, { useState } from 'react';
import { uploadFile } from '../utils/api'; // Adjust the import path based on your file structure

export enum FileClassification {
  Legal = 'Legal',
  Insurance = 'Insurance',
  License = 'Licenses',
  Contract = 'Contract',
  Technical = 'Technical',
}

const Sources = [
  { value: 'clientDocuments', display: 'Client Visible' },
  { value: 'workDocuments', display: 'Internal Documents' },
  { value: 'deliverableDocuments', display: 'Deliverable Documents' },
];

interface FileUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  companyId: string;
  onFileUploadSuccess: (fileInfo: any) => void; // Update the type based on the structure of the fileInfo object
  isGuest?: boolean;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  onClose,
  projectId,
  companyId,
  onFileUploadSuccess,
  isGuest,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState('');
  const [description, setDescription] = useState('');
  const [classification, setClassification] = useState<FileClassification>(
    FileClassification.Technical,
  );
  const [source, setSource] = useState(Sources[1].value);
  const [error, setError] = useState<string | null>(null);

  const handleUpload = async () => {
    if (!file || !fileName || !description || !classification) {
      setError('All fields are required');
      return;
    }
    try {
      const fileInfo = {
        name: fileName,
        description: description,
        classification: classification,
        source: source,
        url: '', // Assume the backend provides the URL after upload
      };

      const uploadedFileInfo = await uploadFile(
        projectId,
        companyId,
        file,
        fileName,
        description,
      );
      fileInfo.url = uploadedFileInfo.data.url; // Set the URL returned by the backend

      onFileUploadSuccess(fileInfo);
      onClose();
    } catch (err) {
      setError('Failed to upload the file. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50  flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-6 w-3/4 relative">
        <h2 className="text-2xl font-bold mb-4">Upload File</h2>
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        {error && <div className="mb-4 text-red-500">{error}</div>}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            File Name
          </label>
          <input
            type="text"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Description
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Classification
          </label>
          <select
            value={classification}
            onChange={(e) =>
              setClassification(e.target.value as FileClassification)
            }
            className="w-full p-2 border border-gray-300 rounded"
          >
            {Object.values(FileClassification).map((classification) => (
              <option key={classification} value={classification}>
                {classification}
              </option>
            ))}
          </select>
        </div>
        {!isGuest && (
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Source
            </label>
            <select
              value={source}
              onChange={(e) => setSource(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              {Sources.map((sourceOption) => (
                <option key={sourceOption.value} value={sourceOption.value}>
                  {sourceOption.display}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Select File
          </label>
          <input
            type="file"
            onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <button
          onClick={handleUpload}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default FileUploadModal;
