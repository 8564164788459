import React, { useState } from 'react';
const ComingSoon: React.FC = () => {
  return (
    <div>
      <h4 className="text-gray-600 text-2xl font-semibold mt-2">
        THIS PAGE IS COMING SOON
      </h4>
    </div>
  );
};

export default ComingSoon;
