import { atom, selector } from 'recoil';
import {
  fetchCompanies,
  createCompany,
  fetchCompany,
  updateCompany,
  deleteCompany,
} from '../utils/api';
import { Company } from '../models/general';
import { startTransition } from 'react';

export const activeCompanyState = atom({
  key: 'activeCompanyState',
  default: {} as Company,
});

export const companyListState = atom({
  key: 'companyListState',
  default: [] as Company[],
});

// Persisting company changes
export const companyCRUDState = selector({
  key: 'companyCRUDState',
  get: ({ get }) => get(companyListState),
  set: ({ set, get }, newValue) => {
    const currentCompanies = get(companyListState);

    if (newValue.action === 'create') {
      createCompany(newValue.data).then((response) => {
        set(companyListState, [...currentCompanies, response.data]);
      });
    } else if (newValue.action === 'update') {
      updateCompany(newValue.id, newValue.data).then((response) => {
        set(
          companyListState,
          currentCompanies.map((company: Company) =>
            company._id === newValue.id ? response.data : company,
          ),
        );
      });
    } else if (newValue.action === 'delete') {
      deleteCompany(newValue.id).then(() => {
        set(
          companyListState,
          currentCompanies.filter(
            (company: Company) => company._id !== newValue.id,
          ),
        );
      });
    }
  },
});
