// customQuillFormats.ts
import Quill from 'quill';

const Block = Quill.import('blots/block');
const Inline = Quill.import('blots/inline');

class CustomBlock extends Block {
  static create(value: string): HTMLElement {
    let node = super.create() as HTMLElement;
    node.setAttribute('class', value);
    return node;
  }

  static formats(node: HTMLElement): string | null {
    return node.getAttribute('class');
  }
}
CustomBlock.blotName = 'custom-block';
CustomBlock.tagName = 'div';

class CustomInline extends Inline {
  static create(value: string): HTMLElement {
    let node = super.create() as HTMLElement;
    node.setAttribute('class', value);
    return node;
  }

  static formats(node: HTMLElement): string | null {
    return node.getAttribute('class');
  }
}
CustomInline.blotName = 'custom-inline';
CustomInline.tagName = 'span';

Quill.register(CustomBlock);
Quill.register(CustomInline);
