import { pdfStyles } from '../../utils/letterAgreement';
import TextList from '../TextList';

function Terms() {
  return (
    <div>
      <h1 style={pdfStyles.exhibitSmallTitle}>Exhibit A</h1>
      <h1 style={pdfStyles.smallTitle}>Terms and Conditions</h1>
      <br />
      <p>
        This Exhibit A, Terms and Conditions, is incorporated into and made a
        part of the agreement (the “Agreement”) by and between{' '}
        <strong>Abacus Engineering, Inc. </strong> (“Consultant”) and{' '}
        <strong>All Clients </strong>
        (“Client”). The Consultant and Client (also referred to collectively as
        the “Parties” and individually as “Party”) agree to the following terms
        and conditions:
      </p>
      <br />
      <br />
      <h2>
        <strong>1. Standard of Care</strong>
      </h2>

      <p>
        <strong>1.1</strong> The standard of care for all services performed or
        furnished by Consultant under the Agreement shall be in conformance with
        the skill and care ordinarily exercised by similar professionals
        providing similar services in the same location at the same time and
        under similar circumstances (the “Standard of Care”). Consultant makes
        no warranty or guarantee, expressed or implied, with respect to its
        services or obligations under the Agreement including, without
        limitation, the implied warranties of merchantability and/or fitness for
        a particular purpose.
      </p>

      <br />
      <h2>
        <strong>2. Limitation of Liability</strong>
      </h2>

      <p>
        <strong>2.1</strong> In recognition of the relative risks and benefits
        of the Agreement to both the Client and Consultant, to the fullest
        extent permitted under applicable law, Client agrees that Consultant’s
        total liability for any and all claims, losses, costs, damages, or
        expenses including, without limitation, reasonable attorneys’ fees and
        costs, of any nature whatsoever, shall not exceed the amount of fee to
        be paid to the Consultant under this Agreement. It is intended that this
        limitation of liability shall apply to any and all liability or cause of
        action, whether in contract, warranty, tort, or otherwise, however
        alleged or arising.
      </p>

      <br />
      <h2>
        <strong>3. Mutual Waiver of Consequential Damages</strong>
      </h2>

      <p>
        <strong>3.1</strong> In no event shall either party under the Agreement
        be liable to the other party, whether in contract, warranty, tort, or
        otherwise, for any special, indirect, incidental, or consequential
        damages of any kind or nature whatsoever.
      </p>

      <br />
      <h2>
        <strong>4. Intellectual Property</strong>
      </h2>

      <p>
        <strong>4.1</strong> “Intellectual Property” as used in these Terms and
        Conditions shall mean any and all copyrightable works, copyrighted
        works, patentable inventions, patented inventions, trademarks, service
        marks, trade secret, know-how, or other proprietary information.
      </p>
      <p>
        <strong>4.2</strong> “Work Product” as used in these Terms and
        Conditions shall mean any and all work created by Consultant in
        performing its services under this Agreement including, without
        limitation, any renderings, drawings, plans, calculations, models, data,
        and/or documents, whether in electronic format or hard copies.
      </p>
      <p>
        <strong>4.3</strong> “Deliverable” as used in these Terms and Conditions
        shall mean a Work Product required to be delivered to Client under the
        Agreement and actually delivered to Client by Consultant.
      </p>
      <p>
        <strong>4.4</strong> Client shall own all Deliverables delivered to
        Client by Consultant.
      </p>
      <p>
        <strong>4.5</strong> Consultant shall own any and all Intellectual
        Property rights in or made a part of any Work Product and/or
        Deliverable. Upon Client’s final and full payment of all fees under the
        Agreement, and provided there is no dispute between Client and
        Consultant related to the Agreement or the services provided by
        Consultant under the Agreement, Consultant shall grant Client an
        irrevocable, royalty-free, world-wide license to use the Intellectual
        Property in any Work Product and/or Deliverable for the sole purpose for
        which the Intellectual Property was created and on the specific project
        that is the subject of the Agreement.
      </p>
      <p>
        <strong>4.6</strong> Client shall not use the Intellectual Property in
        any Work Product or Deliverable for any unlicensed purpose without the
        prior written consent of Consultant. Client agrees to indemnify
        Consultant for Client’s unauthorized use of Intellectual Property, Work
        Product, and Deliverables.
      </p>

      <br />
      <h2>
        <strong>5. Opinions of Probable Costs</strong>
      </h2>

      <p>
        <strong>5.1</strong> Any and all estimates provided by Consultant are
        opinions of probable costs based on information that is reasonably
        available to Consultant. Client acknowledges and agrees that Consultant
        has no control over the cost of labor, materials, equipment or services,
        or the means and methods used by others in determining prices,
        competitive bidding, or market conditions. Client further acknowledges
        and understands that proposals, bids, and/or actual project costs may,
        and probably will vary from the estimates and opinions of probable costs
        provided by Consultant under the Agreement.
      </p>

      <br />
      <h2>
        <strong>6. Construction Means and Methods</strong>
      </h2>

      <p>
        <strong>6.1</strong> Notwithstanding anything under the Agreement, or
        otherwise expressed or implied by Consultant, Consultant shall not have
        control over, charge of, or be responsible, in any way, for the means,
        methods, techniques, sequences or procedures, or for any health or
        safety programs in connection with any construction work arising from
        the Agreement or any Deliverable or Work Product.
      </p>

      <br />
      <h2>
        <strong>7. Conflicts</strong>
      </h2>

      <p>
        <strong>7.1</strong> In the event that any term of these Terms and
        Conditions conflict with the terms and conditions of another portion of
        the Agreement, in all instances, these Terms and Conditions shall
        control and prevail.
      </p>

      <br />
      <h2>
        <strong>8. Force Majeure and Unforeseeable Conditions</strong>
      </h2>

      <p>
        <strong>8.1</strong> Consultant shall not be responsible for and Client
        hereby releases Consultant from any claim, damage, delay or loss
        resulting from: (i) fires, riots, labor disputes, war, terrorism,
        weather, acts of god, or other force majeure; (ii) governmental action
        or failure to act (including, without limitation, plan reviews, permits,
        and/or approvals); (iii) unforeseen circumstances or conditions
        (including, without limitation, unforeseen site conditions); (iv)
        discovery of any hazardous substances or differing site conditions;
        and/or, (v) circumstances or events outside the reasonable control or
        responsibility of Consultant.
      </p>

      <h2>
        <strong>
          <br />
          9. Mutual Waiver and Release Against Individual Employees
        </strong>
      </h2>

      <p>
        <strong>9.1</strong> Consultant and Client agree to release, waive,
        discharge, and covenant not to sue individual employees of the other
        party from any and all liability, claims, demands, actions, and causes
        of action whatsoever arising out of or the result of any loss or injury
        stemming from the performance of the Agreement that may be sustained,
        regardless of whether such loss is caused by the negligence of the
        employee and regardless of whether such liability arises in tort,
        contract, strict liability, or otherwise, to the fullest extent allowed
        by law—except in cases of gross negligence or willful misconduct.
      </p>

      <br />
      <h2>
        <strong>10. Payment</strong>
      </h2>

      <p>
        <strong>10.1</strong> Projects quoted equal to or{' '}
        <strong>
          greater than $10,000 will be invoiced per “Milestone Payments”
          outlined in section 10.2
        </strong>
        . All other projects will be billed as follows:{' '}
        <strong>
          50% due at signing, 50% due at delivery of 100% drawing set
        </strong>
        .
      </p>
      <p>
        <strong>10.2</strong> Milestone payments:
      </p>
      <ul>
        <li>
          <strong>10.2.1</strong> Deposit: <strong>10% of quoted price</strong>{' '}
          due at signing of contract. Project priority and start date set upon
          receipt of Deposit.
        </li>
        <li>
          <strong>10.2.2</strong> Schematic Progress Payment:{' '}
          <strong>40% of quoted price</strong> is due at delivery of Schematic
          Design Plan Set
        </li>
        <li>
          <strong>10.2.3</strong> Permit Submittal Payment:{' '}
          <strong>40% of quoted price</strong>
          is due at delivery of 100% Construction Documents Plan Set
        </li>
        <li>
          <strong>10.2.4</strong> Project Completion Payment:{' '}
          <strong>10% of quoted price</strong> is due upon delivery of sealed
          drawings following application of city comments
        </li>
      </ul>
      <p>
        <strong>10.3</strong> All payments must be current to receive 100%
        drawing sets or sealed drawings.
      </p>
      <p>
        <strong>10.4</strong> If an invoice is not paid within 30 days of issue,
        interest will be charged on the principal balance shown on the invoice.
        Interest will be calculated by multiplying the unpaid balance by the
        periodic rate of the 1.5% per month (18% per annum), or the statutory
        maximum according to applicable state law, if less. The unpaid balance
        will bear interest until paid.
      </p>
      <p>
        <strong>10.5 The</strong> Client acknowledges and agrees that unless
        expressly made within 60 days from the date of the invoice, any
        objections, claims, or disputes related to an invoice shall be waived,
        and said invoice shall be deemed accepted by Client. Clients that elect
        to pay via credit card agree to a 3% surcharge fee.
      </p>

      <br />
      <h2>
        <strong>11. Subconsultants</strong>
      </h2>

      <p>
        <strong>11.1</strong> Texas Tax Code 171.1011 (g)(3) Unless the Client
        expressly states otherwise prior to Consultant commencing performance
        under this Agreement, Client hereby accepts the use of any
        subconsultant, subcontractor or agent of Consultant proposed to be used
        by Consultant in Consultant's proposal or other document submitted to
        Client in pursuing this Agreement; and Any payment made by the Client to
        Consultant that includes fees payable to subconsultant, subcontractor or
        agent of Consultant under this Agreement shall constitute an acceptance
        by Client of Consultant's use of any such subconsultant, subcontractor
        or agent of Consultant under this Agreement.
      </p>

      <br />
    </div>
  );
}

export default Terms;
