// PhoneNumberFormatter.js
import React from 'react';
import PropTypes from 'prop-types';

const formatPhoneNumber = (phoneNumber: number) => {
  if (!phoneNumber) return '';

  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};

const PhoneNumberFormatter = ({ phoneNumber }) => {
  return <span>{formatPhoneNumber(phoneNumber)}</span>;
};

PhoneNumberFormatter.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
};

export default PhoneNumberFormatter;
