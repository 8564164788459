import { Routes, Route, Navigate } from 'react-router-dom';
import Main from './pages/HomePage';
import OriginalLayout from './layouts/OriginalLayout';
import LevelOfEffort from './pages/LevelOfEffort';
import Projects from './pages/ProjectsPage';
import ProjectView from './pages/ProjectPage';
import LOE from './pages/LevelOfEffort';
import Agreement from './pages/AgreementPage';
import Signup from './pages/SignUp';
import Signin from './pages/SignIn';
import PrivateRoute from './components/PrivateRoute';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import ComingSoon from './pages/ComingSoon';
import SignAgreementPage from './pages/SignAgreementPage';
import CreateProjectPage from './pages/CreateProjectPage';
import GuestPage from './pages/GuestPage';
import EmailLoginPage from './pages/EmailLoginPage';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/signup"
          element={
            <OriginalLayout>
              <Signup />
            </OriginalLayout>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/emaillogin" element={<EmailLoginPage />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/guest" element={<GuestPage />} />
          <Route
            path="/project"
            element={
              <OriginalLayout>
                <CreateProjectPage />
              </OriginalLayout>
            }
          />
          <Route
            path="/project/:projectId"
            element={
              <OriginalLayout>
                <ProjectView />
              </OriginalLayout>
            }
          >
            <Route path="loe" element={<LOE />} />
            <Route path="agreement" element={<Agreement />} />
            <Route path="schedule" element={<ComingSoon />} />
          </Route>
          <Route
            path="profile"
            element={
              <OriginalLayout>
                <ProfilePage />
              </OriginalLayout>
            }
          />
          <Route
            path="/projectList"
            element={
              <OriginalLayout>
                <Projects />
              </OriginalLayout>
            }
          />
          <Route
            path="sign-agreement/:projectId"
            element={<SignAgreementPage />}
          />
          <Route
            path="/"
            element={
              <OriginalLayout>
                <Main />
              </OriginalLayout>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to="/login" />} />

        {/* <Route path="/confirm/:id/*" element={<Confirm />} /> */}
        {/* <Route path="/resetpassword/:id*" element={<Recover />} /> */}
        {/* <Route path="/signup" element={<Signup />} /> */}
      </Routes>
    </>
  );
};
export default AppRoutes;
