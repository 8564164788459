import React from 'react';
import { FileDocument } from '../models/general';
import { getSignedUrl } from '../utils/api';

interface FileListProps {
  heading: string;
  files?: FileDocument[];
}

function extractFilePath(url: string, pathIndex: number): string {
  try {
    const parsedUrl = url;
    const pathSegments = parsedUrl.split('/').filter((segment) => segment);

    if (pathIndex < 0 || pathIndex >= pathSegments.length) {
      throw new Error('Path index is out of bounds.');
    }

    return pathSegments.slice(pathIndex).join('/');
  } catch (error) {
    throw new Error('Invalid URL or path index.');
  }
}

const FileList: React.FC<FileListProps> = ({ heading, files }) => {
  const openFile = async (url: string) => {
    try {
      const response = await getSignedUrl({
        fileName: extractFilePath(url, 3),
      });
      console.log('eee', response.data);
      window.open(response.data.url, '_blank');
    } catch {
      alert('This link failed to open. Please try again later.');
    }
  };

  return (
    <div>
      <h3 className="font-bold">{heading}</h3>
      <ul>
        {files &&
          files.map((file, index) => (
            <li
              key={index}
              onClick={() => openFile(file.url)}
              style={{
                cursor: 'pointer',
                color: 'blue',
                textDecoration: 'underline',
              }}
            >
              {file.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FileList;
